import React from "react"
import {Select38Px} from "../Conts";
import GlSelect from "../Components/GlSelect";
class PaymentMethodSelect extends React.Component{
    render() {
        return <GlSelect
            // menuIsOpen={true}
            isMulti={true}
            styles={Select38Px}
            url={'/payment-method'}
            placeholder={"Seçiniz"}
            isClearable={true}
            searchKey={"name"}
            closeMenuOnSelect={true}
            noOptionsMessage={() => "Ödeme yöntemi bulunamadı"}
            {...this.props}
        />;
    }
}
export default PaymentMethodSelect
