import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner} from "reactstrap";
import {Badge} from "@material-ui/core";
import {ClearAll, Close, FiberManualRecord, Notifications} from "@material-ui/icons";
import React from "react";
import NotificationModel from "../Models/NotificationModel";
import moment from "moment-timezone";
import {get_notification_icon} from "../Pages/Admin/Notifications/WebNotifications";
import classnames from "classnames";
import {Scrollbars} from 'react-custom-scrollbars';
import {FormattedMessage} from "react-intl";

const NoNotification = (props) => {
    return <div className={"text-center"}>
        <i className={" icon-feather-message-circle"} style={{fontSize: 40}} color={"dark"}/>
        <h4 className={"pt-1 pb-2"}><FormattedMessage id={"components.notification_bar_no_notification"}/></h4>
    </div>
}

const SingleNotification = React.forwardRef((props, ref) => {
    let {is_read, created, title, type, id} = props
    let admin_profile = localStorage.getItem('admin_profile')
    admin_profile = JSON.parse(admin_profile) || {}
    return <div className={"animated animated-faster"} ref={ref}>
        <DropdownItem toggle={false}>
            <div className={"d-flex"}>
                {!is_read ? <div className={"ml-n3"}>
                    <FiberManualRecord color={"secondary"} style={{
                        fontSize: 10
                    }}/>
                </div> : ""}
                <div className={"w-100"}>
                    <span className={"title"}>{title}</span> <a className={"p-0 bg-light rounded-circle delete-single"}
                                                                onClick={() => {
                                                                    ref.current.classList.add('bounceOutLeft')
                                                                    setTimeout(function () {
                                                                        if (ref.current !== null)
                                                                            ref.current.parentNode.removeChild(ref.current)
                                                                    }, 500)
                                                                    NotificationModel.markDeleted(id, admin_profile.id).then()
                                                                }}><Close color={"secondary"}
                                                                          style={{fontSize: 12}}/></a>
                    <small className={"text-muted w-100 mt-1 d-block"}>{moment(created).fromNow()}</small>
                </div>
                <div className={"bg-dark text-white align-self-end p-0 mt-1 mb-1 mr-n3 rounded text-center"}
                     style={{width: 32, height: 32, lineHeight: "32px"}}>
                    {get_notification_icon(type)}
                </div>
            </div>
        </DropdownItem>
    </div>
})

class NotificationBar extends React.Component {
    state = {
        show: false,
        count: 0,
        data: [],
        loading: false
    }

    markRead = (id) => {
        let admin_profile = localStorage.getItem('admin_profile')
        admin_profile = JSON.parse(admin_profile) || {}
        NotificationModel.markRead(id, admin_profile.id).then(r => {

        })
    }

    markDeleted = (id) => {
        let admin_profile = localStorage.getItem('admin_profile')
        admin_profile = JSON.parse(admin_profile) || {}
        NotificationModel.markDeleted(id, admin_profile.id).then(r => {

        })
    }

    loadNotifications = () => {
        this.setState({loading: true})
        let admin_profile = localStorage.getItem('admin_profile')
        admin_profile = JSON.parse(admin_profile) || {}
        NotificationModel.loadUser(admin_profile.id).then(r => r.json())
            .then(r => {
                let {data} = r
                this.setState({
                    count: data.filter(i => !i.attributes.is_read).length,
                    data,
                    loading: false
                }, () => {

                })
            })
    }

    componentDidMount() {
        this.loadNotifications()
    }

    toggle = () => {
        this.setState({
            show: !this.state.show
        }, () => {
            (this.state.data).filter(item => {
                return !item.attributes.is_read
            }).map(item => {
                this.markRead(item.id)
            })
            this.loadNotifications()
        })
    }

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        this.notifyMenuRef = React.createRef()
    }

    notificationRefs = []
    notifyMenuRef = null

    render() {
        let {data, loading} = this.state
        let {type} = this.props
        let ref
        this.notificationRefs = []

        return <>
            <Dropdown
                isOpen={this.state.show}
                toggle={this.toggle}
                className={""}
            >
                <DropdownToggle className={""} tag={"button"}>
                    <Badge color="error" badgeContent={this.state.count} overlap={"rectangular"}>
                        <Notifications className={type === "client" ? "mt-1" : ""}/>
                    </Badge>
                </DropdownToggle>
                <div>
                    <DropdownMenu className={classnames("app-notification", {
                        left: window.innerWidth > 600 && type !== "client",
                        bottom: window.innerWidth > 600 && type === "client",
                        top: window.innerWidth < 600,
                        "top-right": window.innerWidth < 600
                    })} container="body">
                        <div className={"d-flex w-100"}>
                            <h4 className={"w-100 p-2 text-dark"}><FormattedMessage
                                id={"components.notification_bar_notifications"}/></h4>
                            <a
                                onClick={() => {
                                    this.notifyMenuRef.current
                                        .querySelectorAll('.delete-single')
                                        .forEach((item, index) => {
                                            setTimeout(function () {
                                                item.click()
                                            }, index * 100)
                                        })
                                }}
                                className={"text-right text-dark float-right mt-1 mr-2 cursor-pointer ripple-effect rounded"}><ClearAll/></a>
                        </div>
                        <hr className={"mt-1"}/>
                        <Scrollbars style={{width: "100%", height: 235}}>
                            <div className={"overflow-hidden"} ref={this.notifyMenuRef}>
                                {data.length === 0 ? <NoNotification/> : data.map((item, number) => {
                                    ref = React.createRef()
                                    this.notificationRefs.push({
                                        number: number,
                                        ref: ref
                                    })
                                    let data = item.attributes
                                    return <SingleNotification
                                        key={`single-notification-${number}`}
                                        refs={this.notificationRefs}
                                        number={number}
                                        ref={ref}
                                        {...data} />
                                })}
                            </div>
                        </Scrollbars>
                    </DropdownMenu>
                </div>
            </Dropdown>
        </>;
    }
}

export default React.forwardRef((props, ref) => <NotificationBar
    ref={ref} {...props}
/>);
