import React from "react";
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink,
    Row, Spinner, TabContent, Table, TabPane,
    UncontrolledButtonDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {ApiErrorThrow, debounce, defaultSnackbarOptions, get_default_currency} from "../../../Config";
import {matchPath} from "react-router";
import ShowPrice from "../../../Elements/ShowPrice";
import ClientProfileWidget from "./Widgets/ClientProfileWidget";
import ClientProfileStats from "./Widgets/ClientProfileStats";
import ClientModel from "../../../Models/ClientModel";
import DataTable from "../../../Components/DataTable";
import moment from "moment";
import OrderStatus from "../../../Elements/OrderStatus";
import InputWithIcon from "../../../Elements/InputWithIcon";
import classnames from "classnames";
import Switch from '@material-ui/core/Switch';
import {Card, FormControlLabel, Grid, Tabs, Typography} from "@material-ui/core";
import ClientCustomFieldModel from "../../../Models/ClientCustomFieldModel";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import {Link} from "react-router-dom";
import CreateNewOrder from "../../../Components/CreateNewOrder";
import {GetTitle} from "../../../Routes/AdminRoute";
import AddressModel from "../../../Models/AddressModel";
import BillingProfileWidget from "./Widgets/BillingProfileWidget";
import {withSnackbar} from "react-simple-snackbar";
import SmsHistoryWidget from "./Widgets/SmsHistoryWidget";
import EmailHistoryWidget from "./Widgets/EmailHistoryWidget";
import NotificationHistoryWidget from "./Widgets/NotificationHistoryWidget";
import NotificationCreate from "../../../Components/NotificationCreate";
import PhoneNumberInput from "../../../Elements/PhoneNumberInput";
import AdminAccountModel from "../../../Models/AdminAccountModel";
import {Scrollbars} from 'react-custom-scrollbars';
import {object} from "prop-types";
import {
    BiChevronDown,
    BiChevronUp,
    BiCircle,
    BiEnvelope,
    BsBuilding, HiMail,
    HiPhone,
    IoMdPerson,
    IoPerson
} from "react-icons/all";
import {Select38Px} from "../../../Conts";
import GlSelect from "../../../Components/GlSelect";
import SendNotificationGroup from "../../../Components/SendNotificationGroup";
import {ClientFieldDefaults} from "../../../DefaultValues";
import {FormattedMessage, injectIntl} from "react-intl";
import {react_intl} from "../../../index";

export const defaultUserParams = {
    "username": "string",
    "email": "string",
    "firstname": "string",
    "lastname": "string",
    "mobile_phone": "string",
    "mobile_phone_country_code": "TR",
    "language": "tr",
    "password": "",
    // "balance": 0,
    "user_group": 0
}

const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/admin/clients/:clientId`,
    });
    return (matchProfile && matchProfile.params) || {};
};

const LoadSkeleton = () => {
    return <Row>
        <Col sm={12}>
            <div className={"pt-5 text-center"}>
                <Spinner color={"dark"}/>
            </div>
        </Col>
    </Row>
}

const ProfileFullView = (props) => {
    let {client, toggle, getCustomFieldValue} = props
    return <div className={"w-100 expanded-profile-card"}>
        <div className="d-flex pl-0">
            <div className={"w-100"}>
                <span className="icon"><BiCircle/> <FormattedMessage
                    id={"admin.clients.single_client.name_surname"}/></span>
                <span className={"text"}>{`${client.firstname || ''} ${client.lastname || ''}`}</span>
            </div>
            <div className={"w-100 text-right padding-right-15"}>
                <Button
                    size={"sm"}
                    onClick={toggle}
                    color={"link"}><FormattedMessage id={"admin.clients.single_client.show_small_profile"}/>
                    <BiChevronUp/></Button>
            </div>
        </div>

        <div className="w-100">
            <span className="icon">
                <BiCircle/> <FormattedMessage id={"admin.clients.single_client.account_status"}/>
            </span>
            <span className="text">
                {client.enabled === true ?
                    <span className="float-left verified-badge-with-title"><FormattedMessage
                        id={"admin.clients.single_client.account_status.active"}/></span> :
                    <span className="float-left verified-badge-with-title bg-danger"><FormattedMessage
                        id={"admin.clients.single_client.account_status.passive"}/></span>}
            </span>
        </div>
        <div className="w-100">
            <span className="icon">
                <BiCircle/> <FormattedMessage id={"admin.clients.single_client.account_type"}/>
            </span>
            <span className="text">
                {getCustomFieldValue('account_type') === "corporate" ?
                    <FormattedMessage id={"admin.clients.single_client.account_type.corporate"}/> :
                    <FormattedMessage id={"admin.clients.single_client.account_type.personal"}/>}
            </span>
        </div>
        {getCustomFieldValue('account_type') === "corporate" && <div className="w-100">
            <span className="icon">
                <BiCircle/> <FormattedMessage id={"admin.clients.single_client.account_type.company_name"}/>
            </span>
            <span className="text">
                {getCustomFieldValue('company_name')}
            </span>
        </div>}

        <div className="w-100">
            <span className="icon">
                <HiMail/> <FormattedMessage id={"admin.clients.single_client.email"}/>
            </span>
            <span className="text">
                {client.email}
            </span>
        </div>

        <div className={"w-100"}>
            <span className="icon"><HiPhone/> <FormattedMessage id={"admin.clients.single_client.phone"}/></span>
            <span className="text"><span>{client.mobile_phone}</span></span>
        </div>

    </div>
}

const ProfileSmView = (props) => {
    let {client, toggle} = props
    return <div className={"d-flex w-100 small-profile-card"}>
        <div className={"w-100"}>
                                <span className="icon">
                                    <BiEnvelope/>
                                </span>
            <span className={"text"}>{client.email}</span>
        </div>
        <div className={"w-100"}>
            <span className="icon"><HiPhone/></span>
            <span className="text"><span>{client.mobile_phone}</span></span>
        </div>
        <div className={"w-100 text-right padding-right-15"}>
            <Button
                size={"sm"}
                onClick={toggle}
                color={"info"}><FormattedMessage id={"admin.clients.single_client.show_all_profile"}/> <BiChevronDown/></Button>
        </div>
    </div>
}

class SingleClient extends React.Component {
    tableRef = React.createRef()
    state = {
        loading: true,
        mailSmsActive: "sms",
        client: {
            attributes: {}
        },
        editModal: false,
        modalOpened: false,
        currency_info: {},
        clientEditDetails: {
            attributes: {}
        },
        user_fields: [],
        editClient_fields: [],
        pieSeries: [],
        invoice_information: [],
        editClientModal: false,
        changePw: false,
        phoneVerified: false,
        emailVerified: false,
        accountStatusModal: false,
        addresses: {},
        roleModal: false,
        fullProfile: false
    }


    clientId = null

    switchRoleToggle = () => {
        this.setState({
            roleModal: !this.state.roleModal
        })
    }

    onClientFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    clientModalToggle = () => {
        this.setState({editClientModal: !this.state.editClientModal})
    }
    passwordModalToggle = () => {
        this.setState({editClientModal: !this.state.editClientModal})
    }
    toggleChangePw = () => {
        this.setState({changePw: !this.state.changePw})
    }


    columns = [
        {
            Header: this.props.intl.formatMessage({id: "admin.clients.single_client.table_cols.order_no"}),
            width: "25%",
            mobileWidth: "50%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <div>
                    {`#${row.order_num}`} {row.notes ? <div>
                    <i id={`row-${row.id}-comment`} className={"icon-line-awesome-comments-o"}/>
                    <UncontrolledTooltip target={`row-${row.id}-comment`}>{row.notes}</UncontrolledTooltip></div> : ""}
                </div>
            }
        },
        {
            Header: this.props.intl.formatMessage({id: "admin.clients.single_client.table_cols.status"}),
            accessor: "attributes.status",
            width: "15%",
            mobileWidth: "20%",
            Cell: row => {
                return <OrderStatus status={row.value}/>
            }
        },
        {
            Header: this.props.intl.formatMessage({id: "admin.clients.single_client.table_cols.payment_date"}),
            width: "25%",
            hideMobile: true,
            sortKey: "attributes.due_date",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <div>
                    {typeof row.due_date === "string" ? <span
                        className={"w-100 d-inline-block"}>{moment(row.due_date).format('DD-MM-YYYY')}</span> : "-"}
                    {typeof row.created === "string" ? <small
                        className={"w-100 d-inline-block"}>{moment(row.created).format('DD-MM-YYYY')}</small> : "-"}
                </div>
            }
        },
        {
            Header: this.props.intl.formatMessage({id: "admin.clients.single_client.table_cols.amount"}),
            width: "20%",
            mobileWidth: "25%",
            Cell: data => {
                let row = data.row.original
                let currency = row.currency
                row = row.attributes
                let halfPaid = parseFloat(row.balance) > 0 && row.balance !== row.amount;
                return <div>
                    {halfPaid &&
                    <div>{this.props.intl.formatMessage({id: "admin.clients.single_client.table_cols.amount.total_amount"})}
                        <ShowPrice
                            amount={row.balance} currencysuffix={currency.suffix}
                            currencyprefix={currency.prefix}
                        />
                    </div>}
                    {halfPaid && this.props.intl.formatMessage({id: "admin.clients.single_client.table_cols.amount.balance"})}<ShowPrice
                    currencysuffix={(currency || {}).suffix}
                    amount={row.amount}
                    currencyprefix={(currency || {}).prefix}/>
                </div>
            }
        }
    ]

    constructor(props) {
        super(props);

        let params = getParams(props.location.pathname)
        this.clientId = params.clientId
        this.loadClientData()
        this.clientModalToggle = this.clientModalToggle.bind(this)
        this.profileChangeHandle = this.profileChangeHandle.bind(this)
        this.clientFieldChangeHandle = this.clientFieldChangeHandle.bind(this)
        this.toggleChangePw = this.toggleChangePw.bind(this)
        this.toggleEmailVerified = this.toggleEmailVerified.bind(this)
        this.togglePhoneVerified = this.togglePhoneVerified.bind(this)
        this.saveProfile = this.saveProfile.bind(this)
        this.saveUserFields = this.saveUserFields.bind(this)
        this.saveUserDetails = this.saveUserDetails.bind(this)
        this.accountStatusModalToggle = this.accountStatusModalToggle.bind(this)
        this.loadClientAddressData = this.loadClientAddressData.bind(this)
        this.switchRoleToggle = this.switchRoleToggle.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        let prevPathname = prevProps.location.pathname
        let pathname = this.props.location.pathname
        if (prevPathname !== pathname) {
            let params = getParams(pathname)
            this.clientId = params.clientId;
            this.loadClientData()
        }
    }

    profileChangeHandle = (e, callback) => {
        let clientEditDetails = Object.assign({}, this.state.clientEditDetails)
        clientEditDetails[e.target.name] = e.target.value
        this.setState({clientEditDetails}, () => {
            if (typeof callback === "function")
                callback()
        })
    }

    clientFieldChangeHandle = (e) => {
        let editClient_fields = Object.assign({}, this.state.editClient_fields)
        if (e.target.type === "checkbox" && e.target.name === "account_type") {
            editClient_fields["company_name"] = "";
            editClient_fields[e.target.name] = e.target.checked ? "corporate" : "personal"
        } else if (e.target.type === "checkbox")
            editClient_fields[e.target.name] = e.target.checked ? "yes" : "no"
        else
            editClient_fields[e.target.name] = e.target.value


        this.setState({editClient_fields})
    }

    componentDidMount() {
        let {formatMessage} = this.props.intl
        document.title = GetTitle(formatMessage({id: "admin.clients.single_client.title"}))
    }

    profileToggle = () => {
        this.setState({
            fullProfile: !this.state.fullProfile
        })
    }

    getCustomFieldValue = (slug) => {
        let fields, found
        fields = this.state.user_fields
        if (typeof fields === "object" && Object.keys(fields).length)
            found = fields.find(item => item.field_name === slug)

        if (typeof found === "object" && found !== null)
            return found.field_content;

        if (typeof ClientFieldDefaults[slug] !== "undefined")
            return ClientFieldDefaults[slug]
        return false;
    }

    render() {
        let {
            client,
            editClientModal,
            clientEditDetails,
            changePw,
            editClient_fields
        } = this.state
        let self = this
        let {formatMessage} = this.props.intl
        let isAdmin = typeof client.roles === "object" && (client.roles).indexOf("ROLE_ADMIN") > -1
        let new_role = isAdmin ? formatMessage({id: "admin.clients.single_client.client_label"}) : formatMessage({id: "admin.clients.single_client.manager_label"})
        let new_status = client.status ? formatMessage({id: "admin.clients.single_client.details.status.active"}) : formatMessage({id: "admin.clients.single_client.details.status.passive"})

        let {openSnackbar} = this.props

        let defaultClientGroup = typeof client.user_group === "object" ? {
            value: client.user_group.id,
            label: client.user_group.name
        } : null;

        if (typeof this.state.client_group === "object" && typeof this.state.client_group.value !== "undefined") {
            defaultClientGroup = this.state.client_group
        }

        if (this.state.loading)
            return <LoadSkeleton/>

        return <div className={"single-client-profile-page"}>

            <Modal isOpen={editClientModal} toggle={this.clientModalToggle}>
                <ModalHeader toggle={this.clientModalToggle}><FormattedMessage
                    id={"admin.clients.single_client.edit_details_title"}/></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12} md={6}>
                            <Label><FormattedMessage id={"admin.clients.single_client.details.firstname"}/></Label>
                            <InputWithIcon
                                icon={"icon-material-outline-account-circle"}
                                value={clientEditDetails.firstname}
                                name={"firstname"}
                                onChange={this.profileChangeHandle}/>
                        </Col>
                        <Col sm={12} md={6}>
                            <Label><FormattedMessage id={"admin.clients.single_client.details.lastname"}/></Label>
                            <InputWithIcon
                                icon={"icon-material-outline-account-circle"}
                                value={clientEditDetails.lastname}
                                name={"lastname"}
                                onChange={this.profileChangeHandle}/>
                        </Col>
                        <Col sm={12} md={12}>
                            <Label><FormattedMessage id={"admin.clients.single_client.details.username"}/></Label>
                            <InputWithIcon
                                icon={"icon-material-outline-code"}
                                value={clientEditDetails.username}
                                name={"lastname"}
                                onChange={this.profileChangeHandle}/>
                        </Col>
                        <Col sm={12} md={12}>
                            <Label className={"w-100"}>
                                <FormattedMessage id={"admin.clients.single_client.details.phone"}/>
                                <FormControlLabel
                                    style={{
                                        marginRight: 0,
                                        marginTop: 1,
                                        marginBottom: -1,
                                        float: "right"
                                    }}
                                    color={"primary"}
                                    label={editClient_fields.sms_verification === "yes" ? formatMessage({id: "admin.clients.single_client.details.phone.verified"}) : formatMessage({id: "admin.clients.single_client.details.phone.not_verified"})}
                                    control={<Switch
                                        size={"small"}
                                        onChange={this.togglePhoneVerified}
                                        checked={editClient_fields.sms_verification === "yes"}
                                        inputProps={{'aria-label': 'secondary checkbox'}}
                                    />}
                                />
                            </Label>
                            <PhoneNumberInput
                                value={clientEditDetails.mobile_phone}
                                onChange={(val) => {
                                    if (typeof val !== "object")
                                        val = {}
                                    this.profileChangeHandle({
                                        target: {
                                            name: "mobile_phone_country_code",
                                            value: val.country
                                        }
                                    }, function () {
                                        self.profileChangeHandle({
                                            target: {
                                                name: "mobile_phone",
                                                value: val.number
                                            }
                                        })
                                    })
                                }}
                            />
                        </Col>
                        <Col sm={12} md={12}>
                            <Label className={"w-100"}>
                                <FormattedMessage id={"admin.clients.single_client.details.email"}/>
                                <FormControlLabel
                                    style={{
                                        marginRight: 0,
                                        marginTop: 1,
                                        marginBottom: -1,
                                        float: "right"
                                    }}
                                    color={"primary"}
                                    label={editClient_fields.email_verification === "yes" ? formatMessage({id: "admin.clients.single_client.details.email.verified"}) : formatMessage({id: "admin.clients.single_client.details.email.not_verified"})}
                                    control={<Switch
                                        size={"small"}
                                        checked={editClient_fields.email_verification === "yes"}
                                        onChange={this.toggleEmailVerified}
                                        inputProps={{'aria-label': 'secondary checkbox'}}
                                    />}
                                />
                            </Label>
                            <InputWithIcon
                                autocomplete={"off"}
                                type={"text"}
                                icon={"icon-material-outline-email"}
                                placeholder={formatMessage({id: "admin.clients.single_client.details.email"})}
                                value={clientEditDetails.email}
                                name={"email"}
                                onChange={this.profileChangeHandle}/>
                        </Col>
                    </Row>
                    <Row className={"mt-2 mb-2"}>
                        <Col sm={12} md={{size: 5}} className={"text-center"}>
                            <i className={"icon-feather-lock"}/> <FormattedMessage
                            id={"admin.clients.single_client.details.change_pw"}/> <Switch
                            checked={this.state.changePw}
                            onClick={this.toggleChangePw}
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                            <div className={classnames({"d-none": !changePw})}>
                                <InputWithIcon
                                    icon={"icon-line-awesome-key"}
                                    placeholder={formatMessage({id: "admin.clients.single_client.details.pw"})}
                                    type={"password"}
                                    auto-complete={"off"}
                                    value={clientEditDetails.password}
                                    name={"password"}
                                    onChange={this.profileChangeHandle}/>
                            </div>
                        </Col>

                        <Col sm={12} md={7} className={"text-center"}>
                            <Typography component="div">
                                <Grid component="label" container alignItems="center"
                                      className={"d-block"}
                                      spacing={1}>
                                    <Grid className={"d-inline-block"} item><FormattedMessage
                                        id={"admin.clients.single_client.details.personal"}/></Grid>
                                    <Grid className={"d-inline-block"} item>
                                        <Switch
                                            checked={editClient_fields.account_type === "corporate"}
                                            name={"account_type"}
                                            onChange={this.clientFieldChangeHandle}
                                        />
                                    </Grid>
                                    <Grid className={"d-inline-block"} item><FormattedMessage
                                        id={"admin.clients.single_client.details.corporate"}/></Grid>
                                </Grid>
                            </Typography>
                        </Col>
                        <Col sm={12}
                             className={classnames("mt-2", {"d-none": editClient_fields.account_type !== "corporate"})}>
                            <Label><FormattedMessage id={"admin.clients.single_client.details.company_name"}/></Label>
                            <InputWithIcon
                                autocomplete={"off"}
                                type={"text"}
                                icon={"icon-material-outline-business"}
                                placeholder={formatMessage({id: "admin.clients.single_client.details.company_name_placeholder"})}
                                value={editClient_fields.company_name}
                                name={"company_name"}
                                onChange={this.clientFieldChangeHandle}/>
                        </Col>

                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button color={"primary"} onClick={this.saveProfile}>
                        <FormattedMessage id={"admin.clients.single_client.details.save"}/>
                    </Button>
                </ModalFooter>
            </Modal>

            <ConfirmationModalAlert
                canCancel={true}
                toggle={this.switchRoleToggle}
                isOpen={this.state.roleModal}
                title={formatMessage({
                    id: "admin.clients.single_client.details.user_role_change_question"
                }, {
                    role: new_role
                })}
                buttonAction={() => {
                    AdminAccountModel.switch(this.clientId).then(r => r.json())
                        .then(r => {
                            this.loadClientData()
                            this.switchRoleToggle()
                            this.props.openSnackbar(formatMessage({
                                id: "admin.clients.single_client.details.user_role_changed"
                            }));
                        })
                        .catch(r => ApiErrorThrow(r, this.props.openSnackbar))
                }}
            />

            <ConfirmationModalAlert
                color={"error"}
                icon={"icon-line-awesome-question-circle"}
                isOpen={this.state.accountStatusModal}
                toggle={this.accountStatusModalToggle}
                title={formatMessage({id: "admin.clients.single_client.details.status_are_u_sure"})}
                description={formatMessage({id: "admin.clients.single_client.details.status_change_q"}, {
                    status: new_status
                })}
                buttonAction={() => {
                    ClientModel.toggleStatus(this.clientId).then(item => item.json())
                        .then(item => {
                            if (item.status === true) {
                                this.accountStatusModalToggle()
                                this.loadClientData()
                            }
                        })
                }}
            />


            <Row>
                <Col sm={12} lg={9}>
                    <Card className="bg-white pt-1 rounded margin-top-25 overflow-auto">
                        <div
                            className={"pl-3 pr-3 border-bottom w-100 d-flex"}
                            style={{fontSize: "27px", lineHeight: "60px"}}>
                            <span className={"w-100"}>
                            <span className={"margin-right-10"}>{editClient_fields.account_type === "corporate" ?
                                <BsBuilding/> : <IoPerson/>}</span>
                                {`${client.firstname || ''} ${client.lastname || ''}`}
                            </span>
                            <div className={"w-100 text-right"} style={{fontSize: 14}}>
                                <div style={{width: 200}} className={"float-right mt-2 text-left"}>
                                    <GlSelect
                                        url={'/api/v2/user-group'}
                                        placeholder={formatMessage({id: "admin.clients.single_client.details.user_group_placeholder"})}
                                        onChange={(e) => {
                                            this.setState({
                                                client_group: {
                                                    value: e.value,
                                                    label: e.label
                                                }
                                            })
                                            ClientModel.edit(this.clientId, {
                                                user_group: e.value || ""
                                            }).then(r => r.json())
                                                .then(result => {
                                                    openSnackbar(<div>
                                                        <i className={"icon-line-awesome-check-circle-o"}/>
                                                        <FormattedMessage
                                                            id={"admin.clients.single_client.details.user_group_changed"}/>
                                                    </div>)
                                                })
                                        }}
                                        isClearable={true}
                                        value={defaultClientGroup}
                                        searchKey={"name"}
                                        styles={Select38Px}
                                        closeMenuOnSelect={true}
                                    />
                                </div>
                            </div>
                        </div>

                        {this.state.fullProfile ?
                            <ProfileFullView
                                getCustomFieldValue={this.getCustomFieldValue} toggle={this.profileToggle}
                                client={client}/> :
                            <ProfileSmView toggle={this.profileToggle} client={client}/>}

                        <div className="alone-table stick-table-footer client-detail-orders-table border-top">
                            <h3 className={"mb-3 mt-3 pl-3"}><FormattedMessage
                                id={"admin.clients.single_client.details.orders"}/></h3>
                            <div className={""}>
                                <DataTable
                                    rowClick={(row) => {
                                        row = row.original
                                        this.props.history.push(`/admin/orders/${row.id}`)
                                    }}
                                    container_class={""}
                                    noFakeRow={true}
                                    ref={this.tableRef}
                                    columns={this.columns} dataQuery={{
                                    url: "/api/v2/order",
                                    filters: {
                                        user_id: this.clientId
                                    },
                                    include: ["currency"]
                                }}/>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col sm={12} lg={3}>
                    <Card
                        className={"margin-top-25"}
                        style={{
                            background: "#f7f9fb",
                            overflow: "inherit"
                        }}>
                        <div
                            className={"padding-top-14 padding-bottom-16 padding-left-15 padding-right-15 border-bottom"}>
                            <div>
                                <span className={"text-dark"} style={{lineHeight: "35px"}}><FormattedMessage
                                    id={"admin.clients.single_client.details.account_balance"}/></span>
                                <span
                                    className={classnames("text-dark bg-light border p-1 ml-1 float-right rounded", {})}>
                            <ShowPrice amount={client.balance} color={"white"}
                                       currencyprefix={get_default_currency("prefix")}
                                       currencysuffix={get_default_currency("suffix")}
                            />
                        </span>
                            </div>
                        </div>

                        <div className="padding-top-20 padding-bottom-20 border-bottom">
                            <div className="text-center">
                                <CreateNewOrder
                                    spacing={false}
                                    client={{
                                        label: `#${this.state.client.id} - ${this.state.client.firstname} ${this.state.client.lastname}`,
                                        value: this.state.client.id
                                    }}
                                    color={"dark"}
                                    size={""}
                                    className={"margin-right-10"}
                                    text={formatMessage({id: "admin.clients.single_client.details.new_order"})}
                                />
                                <UncontrolledButtonDropdown direction={"down"} size={"md"}>
                                    <Button id={"caret"} color={"secondary"}
                                            onClick={this.clientModalToggle}><FormattedMessage
                                        id={"admin.clients.single_client.details.edit"}/></Button>
                                    <DropdownToggle split caret className={"border-left"}/>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={() => this.props.history.push(`/admin/clients/${this.clientId}/transactions`)}
                                        ><i className={"icon-feather-list"}/> <FormattedMessage
                                            id={"admin.clients.single_client.details.account_movements"}/></DropdownItem>
                                        <DropdownItem divider/>
                                        <DropdownItem onClick={() => {
                                            this.accountStatusModalToggle()
                                        }}><i
                                            className={"icon-line-awesome-ban"}/> {client.enabled ? <FormattedMessage
                                                id={"admin.clients.single_client.details.change_status_disable"}/> :
                                            <FormattedMessage
                                                id={"admin.clients.single_client.details.change_status_enable"}/>}
                                        </DropdownItem>
                                        <DropdownItem divider/>
                                        <DropdownItem onClick={this.switchRoleToggle}><i
                                            className={" icon-feather-users"}/> {!isAdmin ? <FormattedMessage
                                                id={"admin.clients.single_client.details.change_role_admin"}/> :
                                            <FormattedMessage
                                                id={"admin.clients.single_client.details.change_role_user"}/>}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>

                        <div className="single-client-box message-history-widget bg-white">
                            <div className="d-flex border-bottom">
                                <h4 className={"p-1 w-100"} style={{fontSize: "16px"}}>
                                    <i className=" icon-feather-message-square"/> <FormattedMessage
                                    id={"admin.clients.single_client.details.messages"}/>
                                </h4>

                                <div className={"float-right pr-1 w-50"}>
                                    <select className={"w-100 form-control margin-top-10"} onChange={(e) => {
                                        this.setState({mailSmsActive: e.target.value})
                                    }}>
                                        <option value="sms"
                                                label={react_intl.formatMessage({id: "admin.clients.single_client.details.messages.sms"})}/>
                                        <option value="email"
                                                label={react_intl.formatMessage({id: "admin.clients.single_client.details.messages.email"})}/>
                                        <option value="notification"
                                                label={react_intl.formatMessage({id: "admin.clients.single_client.details.messages.notification"})}/>
                                    </select>
                                </div>
                            </div>
                            <Scrollbars style={{width: "100%", height: 350}}>
                                <TabContent activeTab={this.state.mailSmsActive}>
                                    <TabPane tabId={"sms"}>
                                        <SmsHistoryWidget user_id={this.clientId}/>
                                    </TabPane>
                                    <TabPane tabId={"email"}>
                                        <EmailHistoryWidget user_id={this.clientId}/>
                                    </TabPane>
                                    <TabPane tabId={"notification"}>
                                        <NotificationHistoryWidget user_id={this.clientId}/>
                                    </TabPane>
                                </TabContent>
                            </Scrollbars>
                        </div>

                        <div className={"p-3 border-top text-center"} style={{borderTopWidth: "2.5px"}}>
                            <Row>
                                <Col sm={12} className={"mb-1"}>
                                    <SendNotificationGroup
                                        type={"sms"}
                                        groups={["custom_message", "new_register", "phone_verification"]}
                                        clientId={this.clientId}
                                        openSnackbar={openSnackbar}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <SendNotificationGroup
                                        type={"email"}
                                        color={"primary"}
                                        groups={["custom_message", "new_register", "password_reset_request", "email_verification"]}
                                        clientId={this.clientId}
                                        openSnackbar={openSnackbar}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className={"text-center"}>
                                    <NotificationCreate
                                        className={"mr-2 c-notification-button mt-1"}
                                        label={<>
                                            <i className={"icon-material-outline-add-circle-outline"}/>{" "}
                                            <FormattedMessage
                                                id={"admin.clients.single_client.details.messages.send_notification"}/>
                                        </>}
                                        size={"sm"}
                                        user_selected={client.id} callback={() => {
                                    }}
                                    />
                                </Col>
                            </Row>

                        </div>

                    </Card>
                </Col>
            </Row>
        </div>

    }

    loadClientAddressData() {
        AddressModel.getClientAddress(this.clientId).then(r => r.json())
            .then(r => {
                this.setState({
                    addresses: r.data
                })
            }).catch(r => {
            console.error(r)
        })
    }

    loadClientData() {
        let self = this
        let {openSnackbar} = this.props
        this.setState({loading: true})
        ClientModel.getClientStats(this.clientId).then(r => r.json())
            .then(r => {
                if (typeof r.status === "boolean" && !r.status) {
                    self.props.history.push('/admin/clients')
                    return false;
                }

                r = r.data
                let {user, user_fields, invoice_stats, email_history, sms_history, notifications} = r
                let client = user

                let clientEditDetails = Object.assign(client, {password: ""})
                let editClient_fields = {}
                if (typeof user_fields === "object") {
                    user_fields.map(item => {
                        editClient_fields[item.field_name] = item.field_content
                    })
                }
                let pieSeries = [parseInt((invoice_stats.paid || {}).count),
                    parseInt((invoice_stats.unpaid || {}).count),
                    parseInt((invoice_stats.canceled || {}).count),
                    parseInt((invoice_stats.refunded || {}).count),
                    parseInt((invoice_stats.draft || {}).count)
                ]
                pieSeries = pieSeries.filter(item => item > 0).length > 0 ? pieSeries : false
                if (typeof editClient_fields['sms_verification'] === "string" && editClient_fields['sms_verification'] === "yes") {

                }
                this.setState({
                    client,
                    clientEditDetails,
                    user_fields,
                    editClient_fields,
                    invoice_stats,
                    email_history,
                    sms_history,
                    notifications,
                    pieSeries,
                }, () => {
                    let self = this
                    setTimeout(function () {
                        self.setState({
                            loading: false
                        })
                    }, 200)
                })
            }).catch(r => {
            ApiErrorThrow(r, openSnackbar)
            setTimeout(function () {
                self.props.history.push('/admin/clients')
            }, 5 * 1000)
        })
        this.loadClientAddressData()
    }

    togglePhoneVerified(e) {
        let client_fields = this.state.editClient_fields
        client_fields.sms_verification = e.target.checked ? "yes" : "no"
        this.setState({editClient_fields: client_fields})
    }

    toggleEmailVerified(e) {
        let client_fields = this.state.editClient_fields
        client_fields.email_verification = e.target.checked ? "yes" : "no"
        this.setState({editClient_fields: client_fields})
    }

    saveUserDetails = () => {
        let {openSnackbar} = this.props
        let _clientEditDetails = Object.assign({}, this.state.clientEditDetails)
        let clientParams = {...defaultUserParams}

        Object.keys(defaultUserParams).forEach(key => {
            clientParams[key] = _clientEditDetails[key]
            if (typeof _clientEditDetails[key] === "undefined")
                clientParams[key] = defaultUserParams[key]

            if (typeof clientParams[key] === "object" && typeof clientParams[key].id !== "undefined")
                clientParams[key] = _clientEditDetails[key].id
        })

        if (this.state.changePw) {
            AdminAccountModel
                .password_reset(this.clientId, {
                    password: clientParams['password'],
                    password2: clientParams['password']
                })
                .then(r => {
                })
                .catch(r => ApiErrorThrow(r, openSnackbar))
        }

        ClientModel.edit(this.clientId, clientParams)
            .then(item => item.json())
            .then(item => {
                this.loadClientData()
                this.setState({editClientModal: false, changePw: false})
            }).catch(r => ApiErrorThrow(r, openSnackbar))
    }

    saveUserFields = () => {
        let {openSnackbar} = this.props
        let fields = []
        let client_fields = this.state.editClient_fields
        let _fields = Object.keys(client_fields)
        fields = _fields.map(key => {
            return {
                "field_name": key,
                "field_content": client_fields[key]
            }
        })

        if (typeof fields.account_type === "string" && fields.account_type !== "corporate")
            fields.company_name = ""

        ClientCustomFieldModel.bulkUpdate(this.clientId, fields).then(item => item.json)
            .then(item => {
            })
            .catch(response => ApiErrorThrow(response, openSnackbar))
    }

    saveProfile = async () => {
        await this.saveUserDetails()
        await this.saveUserFields()
        let self = this
        setTimeout(function () {
            self.loadClientData()
        }, 500)
    }

    accountStatusModalToggle = () => {
        this.setState({
            accountStatusModal: !this.state.accountStatusModal
        })
    }

    changeTab(type) {
        this.setState({
            mailSmsActive: type
        })
    }
}

export default injectIntl(withSnackbar(SingleClient, defaultSnackbarOptions))
