import React from "react"
import DataTable from "../../../Components/DataTable";
import moment from "moment";
import {clientTransactionsTableColumns} from "../Clients/SingleClientTransactions";
import AddTransactionTab from "./AddTransactionTab";
import {
    Button,
    ButtonGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    UncontrolledTooltip
} from "reactstrap";
import {Link} from "react-router-dom";
import TransactionModel from "../../../Models/TransactionModel";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import GlRequest from "../../../Lib/GlRequest";
import TransactionPdfButton from "../../../Components/TransactionPdfButton";
import ButtonLoad from "../../../Elements/ButtonLoad";
import InputWithIcon from "../../../Elements/InputWithIcon";
import {ApiErrorThrow} from "../../../Config";
import {
    FiDelete,
    FiEdit3,
    HiReceiptRefund,
    IoMdTrash,
    MdModeEdit,
    RiArrowGoBackFill,
    RiRefund2Line
} from "react-icons/all";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

class TransactionListTab extends React.Component {
    tableRef = React.createRef()
    addTransactionRef = React.createRef()
    state = {
        addTransaction: false,
        editingData: {currency: {}},
        editingId: null,
        deleteConfirm: false,
        invoiceGetting: null,
        process_during: false,
        refundModal: false,
        refundAmount: 0,
        refundLoading: false,
        editWaitingId: 0,
        refundWaitingId: 0,
        deleteWaitingId: 0,
        deleteModalWaiting: false
    }

    refundConfirmToggle = () => {
        this.setState({
            refundModal: !this.state.refundModal,
            refundLoading: false
        })
    }

    deleteConfirmToggle = () => {
        this.setState({
            deleteConfirm: !this.state.deleteConfirm,
            deleteModalWaiting: false
        })
    }

    resetEditing = () => {
        this.setState({
            editingData: {currency: {}},
            editingId: null
        })
    }
    setEditing = (id, after) => {
        TransactionModel.getById(id, ["currency"]).then(r => r.json()).then(item => {
            let data = item.data.attributes

            if (typeof item.included === "object" && typeof item.included.currencies === "object")
                data.currency = item.included.currencies[0].data

            this.setState({
                editingData: data,
                editingId: item.data.attributes.id,
                editWaitingId: 0,
                refundWaitingId: 0,
                deleteWaitingId: 0
            }, typeof after === "function" ? after : false)
        })
    }

    addTransactionToggle = () => {
        this.setState({addTransaction: !this.state.addTransaction})
        if (this.state.addTransaction) {
            if (typeof this.props.reload === "function")
                this.props.reload()
        }
    }

    actionsColumn = [{
        Header: react_intl.formatMessage({id: "admin.orders.trans_list.actions"}),
        width: "15%",
        Cell: data => {
            let row = data.row.original
            return <div>
                <TransactionPdfButton id={row.id}/>
                <ButtonGroup size={"sm"} className={"transaction-actions"}>
                    {row.attributes.total_amount > 0 && <><Button
                        color={"secondary"}
                        disabled={this.state.refundWaitingId === row.id}
                        onClick={() => {
                            this.setState({refundWaitingId: row.id})
                            this.setEditing(row.id, this.refundConfirmToggle)
                        }}
                        id={`refund-${row.id}`}
                    >{this.state.refundWaitingId === row.id ?
                        <Spinner size={"sm"}/> : <RiArrowGoBackFill/>}</Button>
                        <UncontrolledTooltip target={`refund-${row.id}`}>
                            <FormattedMessage id={"admin.orders.trans_list.actions.refund"}/>
                        </UncontrolledTooltip>
                    </>}

                    <UncontrolledTooltip target={`edit-transaction-${row.id}`}>
                        <FormattedMessage id={"admin.orders.trans_list.actions.edit"}/>
                    </UncontrolledTooltip>

                    <Button color={"info"}
                            disabled={this.state.editWaitingId === row.id}
                            id={`edit-transaction-${row.id}`}
                            onClick={() => {
                                this.setState({editWaitingId: row.id})
                                this.setEditing(row.id, this.addTransactionToggle)
                            }}>{this.state.editWaitingId === row.id ?
                        <Spinner size={"sm"}/> : <MdModeEdit/>}</Button>


                    <UncontrolledTooltip target={`delete-transaction-${row.id}`}>
                        <FormattedMessage id={"admin.orders.trans_list.actions.delete"}/>
                    </UncontrolledTooltip>
                    <Button
                        disabled={this.state.deleteWaitingId === row.id}
                        onClick={() => {
                            this.setState({deleteWaitingId: row.id})
                            this.setEditing(row.id, this.deleteConfirmToggle)
                        }} color={"danger"} id={`delete-transaction-${row.id}`}>{this.state.deleteWaitingId === row.id ?
                        <Spinner size={"sm"}/> : <IoMdTrash/>}</Button>
                </ButtonGroup>
            </div>
        }
    }]

    componentDidMount() {
        this.setState({})
    }

    constructor(props) {
        super(props);
        this.addTransactionToggle = this.addTransactionToggle.bind(this)
        this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this)
        this.refundConfirmToggle = this.refundConfirmToggle.bind(this)
    }

    render() {
        const {openSnackbar, closeSnackbar} = this.props
        let {editingData} = this.state;

        let columns = clientTransactionsTableColumns()
        let refund_amount_str = `${editingData.currency.prefix}${editingData.total_amount}${editingData.currency.suffix}`
        return <>
            <ConfirmationModalAlert
                buttonLoading={this.state.refundLoading}
                isOpen={this.state.refundModal}
                toggle={this.refundConfirmToggle}
                title={react_intl.formatMessage({id: "admin.orders.trans_list.actions.refund_sure_title"})}
                buttonAction={() => {

                    if (parseFloat(this.state.refundAmount) > parseFloat(editingData.total_amount)) {
                        openSnackbar(react_intl.formatMessage({id: "admin.orders.trans_list.actions.amount_error"}))
                        return false
                    }

                    if (parseFloat(this.state.refundAmount) <= 0) {
                        openSnackbar(react_intl.formatMessage({id: "admin.orders.trans_list.actions.amount_error2"}))
                        return false
                    }

                    if( moment(editingData.date).isSame(moment(),"day") && parseFloat(this.state.refundAmount) !== parseFloat(editingData.total_amount) )
                    {
                        openSnackbar("Aynı gün içerisinde kısmi iade işlemi yapamazsınız. Tüm tutarı iade etmeyi deneyin.");
                        return false
                    }

                    this.setState({refundLoading: true})

                    TransactionModel.refund(editingData.id, this.state.refundAmount).then(r => {
                        this.tableRef.current.loadData();
                        if (typeof this.props.reload === "function")
                            this.props.reload()
                        this.refundConfirmToggle();
                        this.setState({refundLoading: false});
                    }).catch(e => {
                        this.setState({refundLoading: false})
                        ApiErrorThrow(e, openSnackbar)
                    })
                }}
            >
                <p><FormattedMessage id={"admin.orders.trans_list.actions.refund_sure"} values={{
                    amount: refund_amount_str
                }} tagName={"p"}/></p>

                <Label><FormattedMessage id={"admin.orders.trans_list.actions.refund_amount"}/></Label>
                <InputWithIcon
                    input_type={"number"}
                    value={this.state.refundAmount}
                    onValueChange={(e) => {
                        this.setState({
                            refundAmount: e.floatValue
                        })
                    }}
                    currency={editingData.currency}
                />
            </ConfirmationModalAlert>

            <Modal toggle={this.addTransactionToggle} isOpen={this.state.addTransaction}>
                <ModalHeader toggle={this.addTransactionToggle}>
                    {this.state.editingId ? react_intl.formatMessage({id: "admin.orders.trans_list.edit_trans"}) : react_intl.formatMessage({id: "admin.orders.trans_list.add_trans"})}
                </ModalHeader>
                <ModalBody>
                    <AddTransactionTab
                        defaultValues={this.state.editingData}
                        toggle={this.addTransactionToggle}
                        ref={this.addTransactionRef}
                        {...this.props}
                        reload={() => {
                            this.tableRef.current.loadData()
                            if (typeof this.props.reload === "function")
                                this.props.reload()
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <ButtonLoad
                        loading={this.state.process_during}
                        text={this.state.editingId ? react_intl.formatMessage({id: "admin.orders.trans_list.save"}) : react_intl.formatMessage({id: "admin.orders.trans_list.add"})}
                        onClick={() => {
                            this.setState({process_during: true})
                            this.addTransactionRef.current.process(this.state.editingId || "", () => {
                                this.setState({process_during: false})
                            });
                        }}
                    />
                </ModalFooter>
            </Modal>

            <ConfirmationModalAlert
                color={"error"}
                isOpen={this.state.deleteConfirm}
                toggle={this.deleteConfirmToggle}
                title={react_intl.formatMessage({id: "admin.orders.trans_list.delete_title"})}
                icon={"icon-line-awesome-question-circle"}
                description={react_intl.formatMessage({id: "admin.orders.trans_list.delete_desc"})}
                buttonLoading={this.state.deleteModalWaiting}
                buttonAction={() => {
                    this.setState({deleteModalWaiting:true})
                    TransactionModel.delete(this.state.editingId)
                        .then(r => {
                            openSnackbar(react_intl.formatMessage({id: "admin.orders.trans_list.deleted"}));
                            this.deleteConfirmToggle()
                            this.tableRef.current.loadData()
                        })
                }}
            />

            <DataTable
                noFakeRow={true}
                ref={this.tableRef}
                orderBy={"id"}
                order={"desc"}
                columns={(columns.slice(0, columns.length - 1)).concat(this.actionsColumn)}
                dataQuery={{
                    url: "/api/v2/transaction",
                    filters: {
                        order_id: this.props.orderId
                    },
                    include: ["currency"]
                }}/>
        </>;
    }
}

export default TransactionListTab
