import {Link} from "react-router-dom";
import {get_main_config} from "./index";
import React from "react";
import AdminSidebarProfile from "./Pages/Admin/Blocks/AdminSidebarProfile";
import NotificationBar from "./Components/NotificationBar";

class MobileHeaderAdmin extends React.Component {
    render() {
        let {changeMenu, changeSelectedMenu} = this.props
        return <div className={"w-100 d-flex mobile-panel-header bg-white border-bottom"}>
            <a onClick={this.props.toggle} className="dashboard-responsive-nav-trigger item cursor-pointer">
                            <span className="hamburger hamburger--collapse">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </span>
            </a>
            <div id="logo" className={"item4 w-100 pt-0 pt-md-2 pb-0 pb-md-2 mb-0 border-bottom text-center"}>
                <Link to={"/admin"} onClick={() => {
                    changeMenu("home")
                    changeSelectedMenu({})
                }}><img src={get_main_config("system_general", "logo_url")} alt={""}/></Link>
            </div>
            <div className={"item5 border-bottom"}>
                <div className="d-flex" style={{lineHeight: "50px"}}>
                    <div className={"pr-1 pl-1"}>
                        <NotificationBar/>
                    </div>
                    <div className={"pr-1 pl-1"}>
                        <AdminSidebarProfile
                            chipclass={"bg-dark"}
                            onClick={this.props.toggleProfile}
                            hidename={true}
                            hidelogout={true}
                            hidenotify={true}
                        />
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default MobileHeaderAdmin
