import React from "react"
import * as classnames from "classnames";

class ShowPrice extends React.Component{

    formatMoney(number, decPlaces, decSep, thouSep) {
        let sign = number < 0 ? "-" : "";
        if(typeof number === "undefined") return ""
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces
        decSep = typeof decSep === "undefined" ? "," : decSep
        thouSep = typeof thouSep === "undefined" ? "." : thouSep;
        if(typeof number === "number")
            number = number.toFixed(decPlaces);
        return /*sign + */number.replace('.',decSep).replace(/\B(?=(\d{3})+(?!\d))/g, thouSep);
    }


    render() {
        let {amount, currencyprefix, currencysuffix} = this.props

        return <span className={classnames("price-view", {
            "text-danger": amount<0
        })}{...this.props}>{currencyprefix||''}{this.formatMoney(amount)}{currencysuffix||''}</span>;
    }
}
export default ShowPrice
