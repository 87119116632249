import React from "react";
import {Col, Label} from "reactstrap";
import {debounce, transactionTypes} from "../../../Config";
import DataTable from "../../../Components/DataTable";
import moment from "moment";
import Select from "react-select";
import {Link} from "react-router-dom";
import {OrderStatuses} from "../../../Elements/OrderStatus";
import InputWithIcon from "../../../Elements/InputWithIcon";
import DateRangePickerWrapper from "../../../Components/react-dates/DateRangePickerWrapper";
import ShowPrice from "../../../Elements/ShowPrice";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import {Select38Px} from "../../../Conts";
import {GetTitle} from "../../../Routes/AdminRoute";
import ClientSelect from "../../../Elements/ClientSelect";
import MultiRadio from "../../../Elements/MultiRadio";
import UserLink from "../../../Elements/UserLink";
import {getBankObject} from "../../../Banks";
import PaymentMethodSelect from "../../../Elements/PaymentMethodSelect";

class Transactions extends React.Component {
    tableRef = React.createRef()
    state = {
        clientFilter: "",
        typeFilter: "",
        titleFilter: "",
        transactionNumFilter: "",
        statusFilter: "all",
        dateFilterStart: "",
        dateFilterEnd: "",
        payment_method: ""
    }
    columns = [
        {
            Header: 'ID #',
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            mobileWidth: "20%",
            Cell: row => {
                return <span className="id-cell">{"#" + row.value}</span>
            }
        },
        {
            Header: 'Müşteri',
            accessor: null,
            width: "15%",
            mobileWidth: "20%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                let user = row.order_id.user_id
                return <UserLink user={user}/>
            }
        },
        {
            Header: 'Tutar',
            accessor: null,
            width: "10%",
            mobileWidth: "20%",
            Cell: data => {
                let row = data.row.original
                return <ShowPrice
                    amount={row.attributes.total_amount} currencyprefix={row.currency.prefix}
                    currencysuffix={row.currency.suffix}
                />
            }
        },
        {
            Header: 'Tarih',
            accessor: 'attributes.date',
            width: "15%",
            hideMobile: true,
            Cell: row => {
                return moment(row.value).format("DD-MM-YYYY HH:mm")
            }
        },
        {
            Header: 'Ödeme Yöntemi',
            accessor: null,
            width: "15%",
            mobileWidth: "20%",
            Cell: data => {
                let row = data.row.original
                let currency = row.currency
                row = row.attributes
                let {payment_method, installment, used_point} = row
                let method_class = payment_method.class
                let installmentOutput = parseInt(installment) > 1 ? `${installment} Taksit` : "Peşin";
                let bObject = getBankObject(method_class);
                if(typeof bObject !== "object") bObject = {}
                return <div className={"text-center"}>
                    <div className={"transaction-gw-logo"} style={{
                        background: `url('${(bObject.image || {}).default}') no-repeat center center`
                    }} alt=""></div>
                    <span className={""}>{`${installmentOutput}`}</span>
                    {used_point ? <div>Kullanılan Puan <ShowPrice currencyprefix={currency.prefix}
                                                                  currencysuffix={currency.suffix}
                                                                  amount={row.amount}/></div> : ""}
                </div>
            }
        },
        {
            Header: 'İşlem Numarası',
            accessor: "attributes.transaction_id",
            width: "20%",
            hideMobile: true,
            Cell: row => {
                return row.value
            }
        },
        {
            Header: 'İşlemler',
            width: "15%",
            mobileWidth: "20%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <Link
                    to={`/admin/orders/${row.order_id.id}`}
                    className={"btn btn-sm btn-info"}
                >Faturayı Göster</Link>
            }
        }
    ]
    onClientFilterChanged = debounce(() => {
        if(this.tableRef.current !== null)
            this.tableRef.current.loadData()
    }, 500);

    componentDidMount() {
        document.title = GetTitle('Online Tahsilat Listesi')
    }

    render() {
        let {dateFilterStart, dateFilterEnd, payment_method} = this.state
        return <div className={"filter-150 admin-transactions admin-table"}>


            <TablePageFilterBar
                title={"Online Tahsilat Listesi"}
                description={""}
            >
                <Col sm={6} md={2}>
                    <Label>Müşteri</Label>
                    <ClientSelect
                        isMulti={true}
                        onChange={(value) => {
                            this.setState({clientFilter: value.map(item => item.value)}, () => {
                                if (value.length === 0)
                                    this.onClientFilterChanged()
                            })
                        }}
                        onMenuClose={() => this.onClientFilterChanged()}
                    />
                </Col>
                <Col sm={6} md={2}>
                    <Label>İşlem No / Açıklama</Label>
                    <InputWithIcon
                        onChange={(e) => {
                            this.setState({transactionNumFilter: e.target.value}, this.onClientFilterChanged)
                        }}
                    />
                </Col>
                <Col md={3}>
                    <Label>Tarih Aralığı</Label>
                    <DateRangePickerWrapper
                        showClearDates={true}
                        onChange={(start, end) => {
                            this.setState({
                                dateFilterStart: start ? start.toDate() : null,
                                dateFilterEnd: end ? end.toDate() : null
                            }, this.onClientFilterChanged)
                        }}
                    />
                </Col>
                <Col md={2}>
                    <Label>Ödeme Yöntemi</Label>
                    <PaymentMethodSelect
                        filters={{
                            method_type: "vpos"
                        }}
                        onChange={(vals) => {
                            this.setState({payment_method: vals.map(val => val.value).join(",")})
                        }}
                        onMenuClose={() => {
                            this.onClientFilterChanged()
                        }}
                        closeMenuOnSelect={false}
                    />
                </Col>
                <Col md={3}>
                    <Label>Durum</Label>
                    <MultiRadio
                        value={this.state.statusFilter}
                        onChange={(e) => {
                            this.setState({
                                statusFilter: e.target.value
                            }, this.onClientFilterChanged)
                        }}
                        options={transactionTypes()}
                    />
                </Col>
            </TablePageFilterBar>

            <div className={"container"}>
                <DataTable
                    exportPosition={"bottom"}
                    exportBase={"/api/v2/transaction-excel"}
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    ref={this.tableRef}
                    columns={this.columns}
                    dataQuery={{
                        url: "/api/v2/transaction",
                        filters: {
                            user_id: typeof this.state.clientFilter === "object" && Object.keys(this.state.clientFilter).length ? (this.state.clientFilter).join(",") : "",
                            concat: this.state.transactionNumFilter,
                            status: this.state.statusFilter === "all" ? "" : this.state.statusFilter,
                            date_start: dateFilterStart ? moment(dateFilterStart).format("YYYY-MM-DD") : "",
                            date_end: dateFilterEnd ? moment(dateFilterEnd).format("YYYY-MM-DD") : "",
                            payment_method: payment_method
                        },
                        include: ["currency"]
                    }}/>
            </div>

        </div>;
    }
}

export default Transactions
