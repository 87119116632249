import React from "react"
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input, Spinner,
    Table,
    UncontrolledTooltip
} from "reactstrap";
import {getBankObject} from "../../../Banks";
import {getBankObjectBankAccount} from "../../../BankAccounts";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import PaymentMethodModel from "../../../Models/PaymentMethodModel";
import {defaultSnackbarOptions} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import classnames from "classnames";
import {InfoRounded} from "@material-ui/icons";
import {AiFillInfoCircle, FiInfo} from "react-icons/all";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../index";

class SingleActivePaymentMethod extends React.Component {
    state = {
        processing: false
    }

    setDefault = (id) => {
        let {openSnackbar, reload} = this.props
        this.setState({processing: true})
        PaymentMethodModel.setDefault(id).then(r => r.json())
            .then(r => {
                openSnackbar(react_intl.formatMessage({id: "admin.payment.single_active_method.setted"}))
                this.setState({processing: false})
                reload()
            })
    }

    render() {
        let {details, deleteToggle, editToggle, editInstallmentToggle} = this.props
        details = details.attributes
        if (typeof details.settings === "undefined")
            details.settings = []

        let installments = [], item
        Object.keys(details.settings).filter(key => key.endsWith("installments")).map(key => {
            item = details.settings[key]
            installments[key] = item
        })

        let bankClass = details.class
        let bankDetails = {}
        if (bankClass) {
            if(typeof details.method_type === "string" && details.method_type === "bankaccount")
                bankDetails = getBankObjectBankAccount(bankClass) || {}
            else
                bankDetails = getBankObject(bankClass) || {}
        }


        return <Col sm={12} md={6} lg={4}>
            <Card className={"mb-2 single-active-payment-method"}>
                <CardHeader style={{
                    background: `url('${(bankDetails.image || {}).default}') no-repeat center center`,
                    backgroundSize: "125px",
                    height: 60,
                    width: "calc(100% - 40px)",
                    marginLeft: 20,
                    paddingLeft: 0
                }}>
                    {
                        (
                            typeof details.method_type !== "string" ||
                            details.method_type !== "bankaccount"
                        ) ?
                        details.is_default ? <>
                    <span
                        id={"defaultGatewayTip"}
                        className={"bg-success float-left mt-1 mr-2 rounded-circle text-white text-center"}
                        style={{width: 22, height: 22}}>
                        <i style={{fontSize: 20}} className={"icon-material-outline-check"}/>
                    </span>
                        <UncontrolledTooltip target={"defaultGatewayTip"}><FormattedMessage
                            id={"admin.payment.single_active_method.default"}/></UncontrolledTooltip>
                    </> : <>
                    <span
                        onClick={() => this.setDefault(details.id)}
                        id={`defaultGatewayTip-${details.id}`}
                        className={classnames("gateway-set-default border float-left mt-1 mr-2 rounded-circle text-dark text-center", {
                            "cssspin": this.state.processing
                        })} style={{width: 22, height: 22}}>
                        {this.state.processing ?
                            <Spinner size={"sm"} className={"float-left"} style={{marginTop: 2, marginLeft: 2}}/> :
                            <i style={{fontSize: 20}} className={"icon-material-outline-check"}/>}
                    </span>
                        <UncontrolledTooltip target={`defaultGatewayTip-${details.id}`}><FormattedMessage
                            id={"admin.payment.single_active_method.set_default"}/></UncontrolledTooltip>
                    </> : ""}
                    {" "}
                </CardHeader>
                <CardBody style={{height: 100}}>
                    <Table cellSpacing={0} cellPadding={0} className={"table-no-space table-borderless"}>
                        <tbody>
                        <tr>
                            <th><FormattedMessage id={"admin.payment.single_active_method.name"}/></th>
                            <td>{details.name}</td>
                        </tr>
                        <tr>
                            <th><FormattedMessage id={"admin.payment.single_active_method.description"}/></th>
                            <td>{details.description}</td>
                        </tr>
                        {
                            (typeof details.method_type === "string" && details.method_type === "bankaccount") ||
                            details.is_active ? <></> : <tr>
                            <td colSpan={2} className={"text-center"}>
                                <UncontrolledTooltip target={"no-use-" + details.id}><FormattedMessage
                                    id={"admin.payment.single_active_method.no_use"}/></UncontrolledTooltip>
                                <Badge id={"no-use-" + details.id} color={"danger"}><AiFillInfoCircle/>
                                    {" "}<FormattedMessage id={"admin.payment.single_active_method.no_use_badge"}/></Badge>
                            </td>
                        </tr>}
                        </tbody>
                    </Table>
                </CardBody>
                <CardFooter className={"text-center"}>
                    <ButtonGroup size={"sm"}>
                        {false && bankDetails.installments !== null &&
                        <Button color={"info"} onClick={() => editInstallmentToggle(details)}><i
                            className={"icon-line-awesome-table"}/> <FormattedMessage
                            id={"admin.payment.single_active_method.installments"}/></Button>}
                        <Button onClick={() => editToggle(details)}><i
                            className={"icon-line-awesome-pencil"}/> <FormattedMessage
                            id={"admin.payment.single_active_method.edit"}/></Button>
                        <Button
                            onClick={() => deleteToggle(details)}
                            color={"danger"}><i className={"icon-feather-trash"}/> <FormattedMessage
                            id={"admin.payment.single_active_method.delete"}/></Button>
                    </ButtonGroup>
                </CardFooter>
            </Card>
        </Col>
    }

}

export default withSnackbar(SingleActivePaymentMethod, defaultSnackbarOptions)
