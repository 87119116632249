import React, {Component} from 'react';
import {
    Route,
    Switch,
    withRouter
} from 'react-router-dom';
import {AdminRoute} from "./Routes/AdminRoute";
import {ClientRoute} from "./Routes/ClientRoute";
import {StandardRoute} from "./Routes/StandardRoute";
import ClientHome from "./Pages/Client/ClientHome";
import AdminHome from "./Pages/Admin/AdminHome";
import AdminClients from "./Pages/Admin/Clients/AdminClients";
import SingleClient from "./Pages/Admin/Clients/SingleClient";
import SingleClientTransactions from "./Pages/Admin/Clients/SingleClientTransactions";
import WebNotifications from "./Pages/Admin/Notifications/WebNotifications";
import Orders from "./Pages/Client/Orders";
import OrdersAdmin from "./Pages/Admin/Orders/Orders";
import Transactions from "./Pages/Client/Transactions";
import TransactionsAdmin from "./Pages/Admin/Transactions/Transactions";
import SingleOrder from "./Pages/Admin/Orders/SingleOrder";
import Methods from "./Pages/Admin/Payment/Methods";
import PaymentRules from "./Pages/Admin/Payment/PaymentRules";
import Currencies from "./Pages/Admin/Payment/Currencies";
import AdminAccounts from "./Pages/Admin/Settings/AdminAccounts";
import ClientGroups from "./Pages/Admin/Settings/ClientGroups";
import EmailTemplates from "./Pages/Admin/Settings/EmailTemplates/EmailTemplates";
import SmsTemplates from "./Pages/Admin/Settings/SmsTemplates/SmsTemplates";
import AccessControl from "./Pages/Admin/Settings/AccessControl";
import SystemLogs from "./Pages/Admin/Settings/SystemLogs";
import SingleEmailTemplate from "./Pages/Admin/Settings/EmailTemplates/SingleEmailTemplate";
import SingleSmsTemplate from "./Pages/Admin/Settings/SmsTemplates/SingleSmsTemplate";
import SystemSettings from "./Pages/Admin/Settings/SystemSettings";
import ClientLogin from "./Pages/Client/ClientLogin";
import Profile from "./Pages/Client/Profile";
import BillingInformation from "./Pages/Client/BillingInformation";
import QuickPayment from "./Pages/Client/QuickPayment/QuickPayment";
import OrderSingle from "./Pages/Client/OrderSingle";
import MultiMedia from "./Pages/Admin/MultiMedia/MultiMedia";
import Mailer from "./Pages/Admin/Mailer/Mailer";
import HomeSinglePage from "./Pages/HomeSinglePage";
import MenuManagement from "./Pages/Admin/MenuManagement/MenuManagement";
import PageNotFound from "./Pages/PageNotFound";
import {Redirect} from "react-router";
import PayPageBase from "./Pages/Client/PayPageBase";
import ListAnnouncements from "./Pages/Admin/Content/Announcements/List";
import EditAnnouncement from "./Pages/Admin/Content/Announcements/Edit";
import CreateAnnouncement from "./Pages/Admin/Content/Announcements/Create";
import ListPages from "./Pages/Admin/Content/Pages/List";
import EditPage from "./Pages/Admin/Content/Pages/Edit";
import CreatePage from "./Pages/Admin/Content/Pages/Create";
import HomeSingleAnnouncement from "./Pages/HomeSingleAnnouncement";
import Installer from "./Pages/Installer/Installer";
import {EmptyRoute} from "./Routes/EmptyRoute";
import CpAnnouncements from "./Pages/Client/CpAnnouncements";
import CpAnnouncementDetail from "./Pages/Client/CpAnnouncementDetail";
import SmsNotifications from "./Pages/Admin/Notifications/SmsNotifications";
import EmailNotifications from "./Pages/Admin/Notifications/EmailNotifications";
import PaymentLogs from "./Pages/Logs/PaymentLogs";
import SecuritySettings from "./Pages/Admin/Settings/SecuritySettings";
import ClientRegister from "./Pages/Client/ClientRegister";
import OpenPayment from "./Pages/Client/QuickPayment/OpenPayment";
import BankAccountTransactions from "./Pages/Admin/Transactions/BankAccountTransactions";

class Routes extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Switch
                    history={this.props.history}
                    {...this.props}
                >
                    <EmptyRoute {...this.props} path="/" component={ClientLogin} exact={true}/>
                    <StandardRoute {...this.props} path="/pay/:orderId/:token" component={() => {
                        return <PayPageBase {...this.props} auth={false}/>
                    }}/>
                    <StandardRoute {...this.props} path="/show-invoice/:orderId/:token"
                                   component={() => {
                                       return <OrderSingle {...this.props} auth={false} />
                                   }}
                    />
                    <StandardRoute {...this.props} path="/page/:slug" component={HomeSinglePage} exact={true}/>
                    <EmptyRoute {...this.props} path="/cp/login" component={ClientLogin}/>
                    <EmptyRoute {...this.props} path="/admin/login" component={ClientLogin}/>
                    <EmptyRoute {...this.props} path="/login" component={ClientLogin}/>
                    <EmptyRoute {...this.props} path="/register" component={ClientRegister}/>
                    <ClientRoute {...this.props} path="/cp/settings" component={Profile}/>
                    <ClientRoute {...this.props} path="/cp/announcements/:id" component={CpAnnouncementDetail}/>
                    <ClientRoute {...this.props} path="/cp/announcements" component={CpAnnouncements}/>
                    <ClientRoute {...this.props} path="/cp/transactions" component={Transactions}/>
                    <ClientRoute {...this.props} path="/cp/quick-payment" component={QuickPayment}/>
                    <EmptyRoute {...this.props} path="/open-payment" component={OpenPayment}/>
                    <ClientRoute {...this.props} path="/cp/orders/:orderId/pay" component={() => {
                        return <PayPageBase {...this.props} auth={true}/>
                    }}/>
                    <ClientRoute {...this.props} path="/cp/orders/:orderId" component={OrderSingle}/>
                    <ClientRoute {...this.props} path="/cp/orders" component={Orders}/>
                    <ClientRoute {...this.props} path="/cp/billing-information" component={BillingInformation}/>
                    <ClientRoute {...this.props} path="/cp" component={ClientHome}/>
                    <AdminRoute {...this.props}
                                path="/admin/clients/:clientId/transactions"
                                component={SingleClientTransactions}/>
                    <AdminRoute {...this.props} path="/admin/clients/:clientId" component={SingleClient}/>
                    <AdminRoute {...this.props} path="/admin/clients" component={AdminClients} template={"table"}/>
                    <AdminRoute {...this.props} path="/admin/web-notifications" component={WebNotifications}
                                template={"table"}/>
                    <AdminRoute {...this.props} path="/admin/sms-notifications" component={SmsNotifications}/>
                    <AdminRoute {...this.props} path="/admin/email-notifications" component={EmailNotifications}/>
                    <AdminRoute {...this.props} path="/admin/mailer" component={Mailer}/>
                    <AdminRoute {...this.props} path="/admin/orders/:orderId" component={SingleOrder}/>
                    <AdminRoute {...this.props} path="/admin/orders" component={OrdersAdmin} template={"table"}/>
                    <AdminRoute {...this.props} path="/admin/transactions" component={TransactionsAdmin}
                                template={"table"}
                    />
                    <AdminRoute {...this.props} path="/admin/bank-transactions" component={BankAccountTransactions}
                                template={"table"}
                    />
                    <AdminRoute {...this.props} path="/admin/new-page" component={CreatePage}/>
                    <AdminRoute {...this.props} path="/admin/pages/:pageId" component={EditPage}/>
                    <AdminRoute {...this.props} path="/admin/pages" component={ListPages} template={"table"}/>
                    <AdminRoute {...this.props} path="/admin/new-announcement" component={CreateAnnouncement}/>
                    <AdminRoute {...this.props} path="/admin/announcements/:id" component={EditAnnouncement}/>
                    <AdminRoute {...this.props} path="/admin/announcements" component={ListAnnouncements}
                                template={"table"}/>
                    <AdminRoute {...this.props} path="/admin/multimedia" component={MultiMedia}/>
                    <AdminRoute {...this.props} path="/admin/menu" component={MenuManagement}/>
                    <AdminRoute {...this.props} path="/admin/payment-methods" component={Methods}/>
                    <AdminRoute {...this.props} path="/admin/payment-rules" component={PaymentRules}/>
                    <AdminRoute {...this.props} path="/admin/currencies" component={Currencies}/>
                    <AdminRoute {...this.props} path="/admin/admin-accounts" component={AdminAccounts}
                                template={"table"}/>
                    <AdminRoute {...this.props} path="/admin/client-groups" component={ClientGroups}
                                template={"table"}/>

                    <AdminRoute {...this.props} path="/admin/email-templates/:templateId"
                                component={SingleEmailTemplate}/>
                    <AdminRoute {...this.props} path="/admin/email-templates" component={EmailTemplates}
                                template={"table"}/>

                    <AdminRoute {...this.props} path="/admin/sms-templates/:templateId" component={SingleSmsTemplate}/>
                    <AdminRoute {...this.props} path="/admin/sms-templates" component={SmsTemplates}
                                template={"table"}/>
                    <AdminRoute {...this.props} path="/admin/access-control" component={AccessControl}
                                template={"table"}/>
                    <AdminRoute {...this.props} path="/admin/payment-logs" component={PaymentLogs}/>
                    <AdminRoute {...this.props} path="/admin/other-logs" component={SystemLogs} template={"table"}/>
                    <AdminRoute {...this.props} path="/admin/general-settings" component={SystemSettings}/>
                    <AdminRoute {...this.props} path="/admin/security-settings" component={SecuritySettings}/>
                    <AdminRoute {...this.props} path="/admin" component={AdminHome}/>
                    <EmptyRoute {...this.props} path="/setup" component={Installer}/>
                    <StandardRoute {...this.props} path="/404" component={PageNotFound}/>
                    <Redirect to={'/404'}/>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Routes);
