import React from "react";
import {Col, Row} from "reactstrap";
import FunFacts from "../../Elements/FunFacts";
import StatModel from "../../Models/StatModel";
import moment from "moment";
import {GetTitle} from "../../Routes/AdminRoute";
import LastLoginWidget from "../../Components/LastLoginWidget";
import ActivePaymentMethods from "../../Components/ActivePaymentMethods";
import Moment from 'react-moment'
import CurrenciesWidget from "../../Components/CurrenciesWidget";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../index";

export class CurrentTime extends React.Component {
    render() {
        return <Moment format={"DD/MM/YYYY HH:mm:ss"} interval={1000}></Moment>
    }
}

class AdminHome extends React.Component {
    state = {
        loading: true,
        unpaidInvoice: 0,
        paidToday: 0,
        todayLoading: true,
        paidYesterday: 0,
        paidMonth: 0,
        monthLoading: true,
        paidLastMonth: 0,
        unpaidInvoiceLoading: true
    }

    constructor(props) {
        super(props);

        this.loadStats()
    }

    componentDidMount() {
        document.title = GetTitle('Anasayfa')
    }

    loadStats = () => {
        StatModel.orderStats().then(r => r.json())
            .then(r => {
                let data = r.data
                let unpaidInvoice = typeof data.attributes.unpaid !== "undefined" && typeof data.attributes.unpaid.TRY !== "undefined" ? data.attributes.unpaid.TRY.count : 0
                if(unpaidInvoice === 0)
                    unpaidInvoice = typeof data.attributes.unpaid !== "undefined" && typeof data.attributes.unpaid.EUR !== "undefined" ? data.attributes.unpaid.EUR.count : 0

                if(unpaidInvoice === 0)
                    unpaidInvoice = typeof data.attributes.unpaid !== "undefined" && typeof data.attributes.unpaid.USD !== "undefined" ? data.attributes.unpaid.USD.count : 0

                let unpaidInvoiceLoading = false
                this.setState({unpaidInvoice, unpaidInvoiceLoading})
            })


        // today payment
        StatModel.transactionStats(moment().format("DD-MM-YYYY"), moment().format("DD-MM-YYYY")).then(r => r.json()).then(r => {
            let data = r.data
            let paidToday = typeof data.payment !== "undefined" && typeof data.payment.TRY !== "undefined" ? data.payment.TRY.total : 0,
                todayLoading = false

            this.setState({paidToday, todayLoading})
        })

        // yesterday payment
        StatModel.transactionStats(moment().subtract(1, "days").format("DD-MM-YYYY"), moment().subtract(1, "days").format("DD-MM-YYYY")).then(r => r.json()).then(r => {
            let data = r.data
            let paidYesterday = typeof data.payment !== "undefined" && typeof data.payment.TRY !== "undefined" ? data.payment.TRY.total : 0
            this.setState({paidYesterday})
        })

        // this month payment
        StatModel.transactionStats(moment().startOf("month").format("DD-MM-YYYY"), moment().endOf("month").format("DD-MM-YYYY")).then(r => r.json()).then(r => {
            let data = r.data
            let paidMonth = typeof data.payment !== "undefined" && typeof data.payment.TRY !== "undefined" ? data.payment.TRY.total : 0,
                monthLoading = false
            this.setState({paidMonth, monthLoading})
        })

        // last month payment
        StatModel.transactionStats(moment().subtract(1, "month").startOf("month").format("DD-MM-YYYY"), moment().subtract(1, "month").endOf("month").format("DD-MM-YYYY")).then(r => r.json()).then(r => {
            let data = r.data
            let paidLastMonth = typeof data.payment !== "undefined" && typeof data.payment.TRY !== "undefined" ? data.payment.TRY.total : 0
            this.setState({paidLastMonth})
        })
    }

    render() {
        let {
            monthLoading, todayLoading,
            paidToday, paidYesterday, paidMonth, paidLastMonth,
            unpaidInvoice, unpaidInvoiceLoading
        } = this.state
        return <div>
            <div className="dashboard-box">
                <div className={"content with-padding bg-light border-bottom"}>
                    <Row>
                        <Col sm={12} md={5} className={"border-bottom margin-bottom-30 padding-bottom-10"}>
                            <h1><FormattedMessage tagName={"h1"} id={"admin.admin_home.welcome"}/></h1>
                        </Col>
                        <Col sm={12} md={7} className={"border-bottom margin-bottom-30 padding-bottom-10 text-right"}>
                            <h3><i className={"icon-feather-clock"}/> <CurrentTime/></h3>
                        </Col>
                        <Col sm={12}>
                            <Row>
                                <Col sm={12} md={6} lg={4}>
                                    <div className="fun-facts-container mb-1">
                                        <FunFacts
                                            loading={todayLoading}
                                            title={react_intl.formatMessage({id: "admin.admin_home.today_paid"})}
                                            icon={"icon-feather-clock"}
                                            icon_color={"rgb(51, 51, 51)"}
                                            count={paidToday}
                                            currency_symbol={"₺"}
                                            prev_count={paidYesterday}
                                            prev_text={react_intl.formatMessage({id: "admin.admin_home.yesterday"})}
                                            history={this.props.history}
                                            mainLink={`/admin/orders?status=fully_paid&created_start=${moment().format('YYYY-MM-DD')}&created_end=${moment().format('YYYY-MM-DD')}`}
                                            subLink={`/admin/orders?status=fully_paid&created_start=${moment().subtract(1, "day").format('YYYY-MM-DD')}&created_end=${moment().subtract(1, "day").format('YYYY-MM-DD')}`}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="fun-facts-container mb-1">
                                        <FunFacts
                                            loading={monthLoading}
                                            title={react_intl.formatMessage({id: "admin.admin_home.this_month"})}
                                            icon={"icon-feather-trending-up"}
                                            icon_color={"rgb(42, 65, 230)"}
                                            icon_bg_color={"rgba(42, 65, 230, 0.07)"}
                                            prev_text={react_intl.formatMessage({id: "admin.admin_home.last_month"})}
                                            prev_count={paidLastMonth}
                                            history={this.props.history}
                                            count={paidMonth} currency_symbol={"₺"}
                                            mainLink={`/admin/orders?status=fully_paid&created_start=${moment().startOf("month").format('YYYY-MM-DD')}&created_end=${moment().endOf("month").format('YYYY-MM-DD')}`}
                                            subLink={`/admin/orders?status=fully_paid&created_start=${moment().subtract(1, "month").startOf("month").format('YYYY-MM-DD')}&created_end=${moment().subtract(1, "month").endOf("month").format('YYYY-MM-DD')}`}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="fun-facts-container mb-1">
                                        <FunFacts
                                            title={react_intl.formatMessage({id: "admin.admin_home.unpaid_order"})}
                                            icon={"icon-line-awesome-line-chart"}
                                            icon_color={"rgb(255, 193, 7)"}
                                            icon_bg_color={"rgba(255, 193, 7, 0.07)"}
                                            count={unpaidInvoice}
                                            prev_count={null}
                                            history={this.props.history}
                                            loading={unpaidInvoiceLoading}
                                            mainLink={`/admin/orders?status=unpaid`}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={12} md={6} lg={4}>
                            <LastLoginWidget history={this.props.history}/>
                        </Col>
                        <Col sm={12} lg={4}>
                            <ActivePaymentMethods/>
                        </Col>
                        <Col sm={12} lg={4}>
                            <CurrenciesWidget/>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>;
    }
}

export default AdminHome
