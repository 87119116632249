import React from "react"
import {Link} from "react-router-dom";
class UserLink extends React.Component{
    render() {
        let {user} = this.props
        if(typeof user !== "object" || user === null) user = {}
        return <Link to={`/admin/clients/${user.id}`}>
            {user.firstname} {user.lastname}
        </Link>;
    }
}
export default UserLink
