import React from "react";
import {Button, ButtonGroup, Col, Input, Label, Row, Spinner, UncontrolledTooltip} from "reactstrap";
import {ApiErrorThrow, debounce, mobileWidthEnd, notificationTypes} from "../../../Config";
import DataTable from "../../../Components/DataTable";
import moment from "moment";
import GlSelect, {GlOption, GlValueContainer} from "../../../Components/GlSelect";
import Select from "react-select";
import {Link} from "react-router-dom";
import OrderStatus, {OrderStatuses} from "../../../Elements/OrderStatus";
import InputWithIcon from "../../../Elements/InputWithIcon";
import DateRangePickerWrapper from "../../../Components/react-dates/DateRangePickerWrapper";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import {generateQueryParams, Select38Px} from "../../../Conts";
import StatusChangeModel from "../../../Components/StatusChangeModel";
import CreateNewOrder from "../../../Components/CreateNewOrder";
import {GetTitle} from "../../../Routes/AdminRoute";
import ShowPrice from "../../../Elements/ShowPrice";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import OrderModel from "../../../Models/OrderModel";
import {withSnackbar} from "react-simple-snackbar";
import ClientSelect from "../../../Elements/ClientSelect";
import GlRequest from "../../../Lib/GlRequest";
import OrderPdfButton from "../../../Components/OrderPdfButton";
import UserLink from "../../../Elements/UserLink";
import OrderDuplicate from "../../../Components/OrderDuplicate";
import ExcelExportButton from "../../../Components/ExcelExportButton";
import {IoMdTrash} from "react-icons/all";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../index";
import classnames from "classnames";

export const filter2String = (filters) => {
    let filtersString = ""
    Object.keys(filters).map((key,i) => {
        let item = filters[key]
        if(!item)
            return ""
        if(i)
            filtersString+='&'
        filtersString += `${key}=${item}`
    })
    return filtersString
}

class Orders extends React.Component {
    tableRef = React.createRef()
    queryParameters = null

    state = {
        // clientFilter: "",
        // typeFilter: "",
        // titleFilter: "",
        // orderNumFilter: "",
        // statusFilter: "",
        // dateFilterStart: "",
        // dateFilterEnd: "",
        orderDeleteModal: false,
        orderDeleteId: 0,
        invoiceGetting: null
    }

    pageUrlBase = "/admin/orders"

    orderDeleteModalToggle = () => {
        let {orderDeleteModal} = this.state
        orderDeleteModal = !orderDeleteModal
        this.setState({
            orderDeleteModal
        })
    }

    columns = [
        {
            Header: react_intl.formatMessage({id:"admin.orders.order.table_cols.id"}),
            accessor: 'id',
            sortKey: 'id',
            // hideMobile: true,
            width: "10%",
            mobileWidth: "20%",
            Cell: row => {
                return <span className={"id-cell"}>{"#" + row.value}</span>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.orders.order.table_cols.client"}),
            accessor: 'attributes.user_id',
            width: "20%",
            mobileWidth: "20%",
            Cell: row => {
                return <UserLink user={row.value}/>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.orders.order.table_cols.order_num"}),
            accessor: null,
            width: "15%",
            hideMobile: true,
            mobileWidth: "30%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <div>{row.order_num}{row.notes ? <>
                    <UncontrolledTooltip target={`order-${row.id}-note`}>{row.notes}</UncontrolledTooltip>
                    <i id={`order-${row.id}-note`} className={"icon-line-awesome-comment ml-1"}/></> : ""}</div>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.orders.order.table_cols.amount"}),
            sortKey: 'amount',
            width: "15%",
            mobileWidth: "20%",
            Cell: data => {
                let row = data.row.original
                return <ShowPrice
                    amount={row.attributes.amount}
                    currencyprefix={row.currency.prefix}
                    currencysuffix={row.currency.suffix}
                />
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.orders.order.table_cols.created"}),
            accessor: 'attributes.created',
            width: "10%",
            hideMobile: true,
            Cell: row => {
                return moment(row.value).format("DD-MM-YYYY")
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.orders.order.table_cols.status"}),
            accessor: "attributes.status",
            width: "15%",
            mobileWidth: "20%",
            Cell: row => {
                return <OrderStatus status={row.value}/>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.orders.order.table_cols.actions"}),
            width: "30%",
            mobileWidth: "20%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <div className={classnames("justify-content-around actions-cell", {
                    "d-flex": window.innerWidth > mobileWidthEnd
                })}>
                    <Link
                        to={`/admin/orders/${row.id}`}
                        className={"btn btn-sm btn-dark mr-1"}
                    ><FormattedMessage id={"admin.orders.order.table_cols.actions.details"} /></Link>
                    <div className={"d-none d-md-flex"}>
                        <OrderPdfButton id={row.id} />

                        <OrderDuplicate
                            text={react_intl.formatMessage({id:"admin.orders.order.table_cols.actions.clone"})}
                            after_complete={() => {
                                this.onClientFilterChanged()
                            }}
                            id={row.id} />

                        <StatusChangeModel orderId={row.id} reload={() => {
                            this.onClientFilterChanged()
                        }}/>

                        <UncontrolledTooltip target={`order-delete-${row.id}`}>
                            <FormattedMessage id={"admin.orders.order.table_cols.actions.remove"} />
                        </UncontrolledTooltip>
                        <Button
                            id={`order-delete-${row.id}`}
                            onClick={() => {
                                this.setState({orderDeleteId: row.id}, this.orderDeleteModalToggle)
                            }}
                            color={"danger"} className={"ml-1"} size={"sm"}><IoMdTrash /></Button>
                    </div>
                </div>
            }
        }
    ]
    onClientFilterChanged = debounce(() => {
        if(this.tableRef.current === null)
            return;

        this.tableRef.current.loadData()
        let url = '?'
        url += generateQueryParams(this.getFilters())
        this.props.history.push(url)
    }, 500);

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"admin.orders.order.title"}))
    }

    constructor(props) {
        super(props);
        this.orderDeleteModalToggle = this.orderDeleteModalToggle.bind(this)
        this.queryParameters = new URLSearchParams(window.location.search);
    }


    getFilters = () => {
        let filters = [
            {
                slug: "user_id",
                stateSlug: "clientFilter"
            },
            {
                slug: "concat",
                stateSlug: "orderNumFilter"
            },
            {
                slug: "status",
                stateSlug: "statusFilter"
            },
            {
                slug: "created_start",
                stateSlug: "dateFilterStart"
            },
            {
                slug: "created_end",
                stateSlug: "dateFilterEnd"
            }
        ];

        let filterResult = {}
        filters.forEach(filter => {
            if(typeof this.state[filter.stateSlug] !== "undefined")
                filterResult[filter.slug] = this.state[filter.stateSlug];
            else if(this.queryParameters.get(filter.slug))
                filterResult[filter.slug] = this.queryParameters.get(filter.slug);
            else
                filterResult[filter.slug] = ""
        });

        console.log(filterResult)

        return filterResult
    }


    render() {
        let {openSnackbar} = this.props
        let selected_filters = this.getFilters()
        let selected_status = selected_filters['status']
        if(typeof selected_status !== "string")
            selected_status = "";


        return <div className={"admin-orders admin-table"}>

            <ConfirmationModalAlert
                isOpen={this.state.orderDeleteModal}
                toggle={this.orderDeleteModalToggle}
                icon={"icon-feather-alert-circle"}
                title={react_intl.formatMessage({id:"admin.orders.order.delete_modal.title"})}
                description={ react_intl.formatMessage({id:"admin.orders.order.delete_modal.desc"}, {
                    num: this.state.orderDeleteId
                }) }
                buttonAction={() => {
                    OrderModel.delete(this.state.orderDeleteId).then(r => {
                        this.setState({
                            orderDeleteModal: false,
                            orderDeleteId: 0
                        }, this.onClientFilterChanged)
                    }).catch(error => ApiErrorThrow(error, openSnackbar))
                }}
            />

            <TablePageFilterBar
                title={react_intl.formatMessage({id:"admin.orders.order.filter.title"})}
                description={""}
            >
                <Col sm={6} lg={4} xl={3} className={"d-none d-xl-inline-block"}>
                    <Label>Müşteri</Label>
                    <ClientSelect
                        onChange={(value) => {
                            this.setState({clientFilter: value.map(item => item.value)})
                        }}
                        countItemLabel={react_intl.formatMessage({id:"admin.orders.order.filter.count_item"})}
                        // menuIsOpen={true}
                        onMenuClose={(val) => {
                            this.onClientFilterChanged()
                        }}
                    />
                </Col>
                <Col sm={6} lg={4} xl={2}>
                    <Label><FormattedMessage id={"admin.orders.order.filter.order_num"} /></Label>
                    <Input
                        onChange={(e) => {
                            this.setState({orderNumFilter: e.target.value}, this.onClientFilterChanged)
                        }}
                    />
                </Col>
                <Col sm={6} lg={4} xl={2}>
                    <Label><FormattedMessage id={"admin.orders.order.filter.status"} /></Label>
                    <Select
                        placeholder={react_intl.formatMessage({id:"admin.orders.order.filter.status_placeholder"})}
                        onChange={(value) => {
                            this.setState({
                                statusFilter: value.length ? value.map(item => item.value) : []
                            }, () => {
                                if(!value.length)
                                    this.onClientFilterChanged()
                            })
                        }}
                        onMenuClose={() => {
                            this.onClientFilterChanged()
                        }}
                        styles={Select38Px}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                            Option: GlOption,
                            ValueContainer: GlValueContainer
                        }}
                        isMulti={true}
                        defaultValue={OrderStatuses().filter(item => (selected_status).split(",").indexOf(item.slug) > -1).map(item => {
                            return {label: item.label, value: item.slug}
                        })}
                        options={OrderStatuses().filter(item => item.slug !== "unknown").map(item => {
                            return {label: item.label, value: item.slug}
                        })}
                    />


                </Col>
                <Col sm={6} lg={4} xl={3}>
                    <div>
                        <Label><FormattedMessage id={"admin.orders.order.filter.date_range"} /></Label>
                        <DateRangePickerWrapper
                            showClearDates={true}
                            initialStartDate={typeof selected_filters['created_start'] === "string" && selected_filters['created_start'] !== "" ? moment(selected_filters['created_start'], "YYYY-MM-DD") : ""}
                            initialEndDate={typeof selected_filters['created_end'] === "string" && selected_filters['created_end'] !== "" ? moment(selected_filters['created_end'], "YYYY-MM-DD") : ""}
                            onChange={(start, end) => {
                                if(start !== null && end === null || start === null && end !== null)
                                    return false
                                this.setState({
                                    dateFilterStart: start ? start.toDate() : null,
                                    dateFilterEnd: end ? end.toDate() : null
                                }, this.onClientFilterChanged)
                            }}
                        />
                    </div>
                </Col>
                <Col xl={2} className={"text-right padding-top-30"}>
                    <CreateNewOrder spacing={false}/>
                </Col>
            </TablePageFilterBar>

            <div className={"container"}>
                <DataTable
                    exportPosition={"bottom"}
                    exportBase={"/api/v2/invoice-excel"}
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    rowClick={(row) => {
                        row = row.original
                        this.props.history.push(`/admin/orders/${row.id}`)
                    }}
                    ref={this.tableRef}
                    columns={this.columns}
                    dataQuery={{
                        url: "/api/v2/order",
                        filters: this.getFilters(),
                        include: ["currency"]
                    }}/>
            </div>
        </div>;
    }
}

export default withSnackbar(Orders)
