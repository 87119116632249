import {Button, Card, Col, Modal, ModalBody, ModalHeader, Row, Spinner, UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import React from "react";
import ButtonRoundedWithIcon from "../../../../Elements/ButtonRoundedWithIcon";
import DayPicker from "../../../../Components/react-dates/DayPicker";
import OrderModel from "../../../../Models/OrderModel";
import GlSelect from "../../../../Components/GlSelect";
import PaymentMethodModel from "../../../../Models/PaymentMethodModel";
import PaymentMethodSelect from "../../../../Elements/PaymentMethodSelect";
import {ApiErrorThrow} from "../../../../Config";
import UserLink from "../../../../Elements/UserLink";
import {react_intl} from "../../../../index";
import {FormattedMessage} from "react-intl";
import CustomCheckbox from "../../../../Elements/CustomCheckbox";
import GlRequest from "../../../../Lib/GlRequest";

const DateChangeModel = props => <Modal isOpen={props.isOpen} centered={true} toggle={props.toggle}>
    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody className={"text-center"}>
        <DayPicker
            initialVisibleMonth={() => moment(props.orderDetails[props.slug])}
            renderDayContents={(day) => {
                return (day.format("DD-MM-YYYY") === moment(props.orderDetails[props.slug]).format("DD-MM-YYYY") ?
                    <i className={"icon-line-awesome-check"}/> : day.format('D'))
            }}
            horizontalCenter={true}
            style={{margin: "0 auto"}}
            onDayClick={(day) => {
                let Details = {}
                Details[props.slug] = day.format('YYYY-MM-DDT00:00:00')
                OrderModel.modify(props.orderId, Details).then(r => r.json()).then(result => {
                    props.toggle()
                    props.reload()
                }).catch(r => ApiErrorThrow(r, props.openSnackbar))
            }}
        />
    </ModalBody>
</Modal>

class LeftTable extends React.Component {
    constructor(props) {
        super(props);
        this.payment_date_modal_toggle = this.payment_date_modal_toggle.bind(this)
        this.due_date_modal_toggle = this.due_date_modal_toggle.bind(this);
    }

    state = {
        due_date_modal: false,
        payment_date_modal: false,
        isPrepaymentOrder_Modified: null,
        isPrepaymentOrder_Saving: false
    }
    due_date_modal_toggle = () => {
        this.setState({
            due_date_modal: !this.state.due_date_modal
        })
    }
    payment_date_modal_toggle = () => {
        this.setState({
            payment_date_modal: !this.state.payment_date_modal
        })
    }


    render() {
        let {openSnackbar, orderDetails, orderId, isPrepaymentOrder} = this.props
        let {isPrepaymentOrder_Modified, isPrepaymentOrder_Saving} = this.state
        let self = this;
        let isPrepaymentOrder_Value;

        if (typeof isPrepaymentOrder_Modified === "boolean")
            isPrepaymentOrder_Value = isPrepaymentOrder_Modified;
        else
            isPrepaymentOrder_Value = isPrepaymentOrder;

        if (typeof orderDetails.payment_method === "undefined")
            orderDetails.payment_method = {}
        return (
            <div>
                <Card className={"invoice-client-detail mt-3 margin-bottom-5"} style={{width: 400}}>
                    <a className={"padding-left-5 padding-top-2 padding-right-5"}><i className="icon-feather-user"/>
                        &nbsp;<FormattedMessage id={"admin.orders.details.left_table.client"}/>
                        <span
                            className={"padding-left-5 text-primary text-right client-profile-url float-right padding-right-5 text-dotdot"}>
                        <UserLink user={orderDetails.user_id}/>
                    </span>
                    </a>
                </Card>
                <Card className={"invoice-client-detail margin-bottom-5"} style={{width: 400}}>
                    <a className={"padding-left-5 padding-top-2 padding-right-5"}>
                        <i className="icon-line-awesome-calendar-o"/> <FormattedMessage
                        id={"admin.orders.details.left_table.created"}/> <span
                        className="float-right">{moment(orderDetails.created).format("DD-MM-YYYY HH:mm")}</span>
                    </a>
                </Card>
                <Card className={"invoice-client-detail margin-bottom-5"} style={{width: 400}}>
                    <a className={"padding-left-5 padding-top-2 padding-right-5"}>
                        <i className="icon-line-awesome-calendar-o"/>
                        &nbsp;<FormattedMessage id={"admin.orders.is_prepayment"}/>
                        <span
                            style={{marginTop: -2}}
                            className="float-right">
                            {isPrepaymentOrder_Saving ? <span style={{marginRight: 10}}>
                                <Spinner size={"sm"} />
                            </span> :
                            <CustomCheckbox checked={isPrepaymentOrder_Value}
                                            onClick={(e) => {
                                                this.setState({
                                                    isPrepaymentOrder_Saving: true,
                                                    isPrepaymentOrder_Modified: !isPrepaymentOrder_Value
                                                }, () => {
                                                    setTimeout(function () {
                                                        self.save_prepayment_order(self.state.isPrepaymentOrder_Modified)
                                                    }, 500);
                                                });
                                            }}/> }
                        </span>
                    </a>
                </Card>
                <Card className={"invoice-client-detail margin-bottom-5"} style={{width: 400}}>
                    <a className={"padding-left-5 padding-top-2 padding-right-5"}>
                        <i className="icon-line-awesome-calendar-times-o"/> <FormattedMessage
                        id={"admin.orders.details.left_table.due_date"}/>
                        {" "}
                        <span className="float-right">
                                {moment(orderDetails.due_date).format("DD-MM-YYYY")}
                            {" "}
                            <ButtonRoundedWithIcon
                                tooltip={react_intl.formatMessage({id: "admin.orders.details.left_table.change"})}
                                size={20}
                                color={"primary"}
                                style={{marginTop: -5, marginRight: 5}}
                                onClick={this.due_date_modal_toggle}
                                icon={"icon-material-outline-compare-arrows"}/>
                            </span>
                    </a>
                </Card>
                <DateChangeModel
                    openSnackbar={openSnackbar}
                    title={react_intl.formatMessage({id: "admin.orders.details.left_table.set_due_date"})}
                    toggle={this.due_date_modal_toggle} state={this.state} {...this.props}
                    isOpen={this.state.due_date_modal} slug={"due_date"}/>

                <Card className={"invoice-client-detail margin-bottom-5"} style={{width: 400}}>
                    <a className={"padding-left-5 padding-top-2 padding-right-5"}>
                        <i className="icon-line-awesome-calendar-check-o"/> <FormattedMessage
                        id={"admin.orders.details.left_table.payment_date"}/>
                        <span className="float-right">
                                {orderDetails.payment_date && moment(orderDetails.payment_date).format("DD-MM-YYYY")}
                            {orderDetails.payment_date ? " " : "- "}
                            {orderDetails.payment_date && <ButtonRoundedWithIcon
                                tooltip={react_intl.formatMessage({id: "admin.orders.details.left_table.change"})}
                                size={20}
                                onClick={this.payment_date_modal_toggle}
                                color={"primary"}
                                style={{marginTop: -5, marginRight: 5}}
                                icon={"icon-material-outline-compare-arrows"}/>
                            }
                            </span>
                    </a>
                </Card>
                <DateChangeModel
                    openSnackbar={openSnackbar}
                    title={react_intl.formatMessage({id: "admin.orders.details.left_table.payment_date"})}
                    toggle={this.payment_date_modal_toggle} state={this.state} {...this.props}
                    isOpen={this.state.payment_date_modal} slug={"payment_date"}/>


                <Card className={"invoice-client-detail margin-bottom-5 p-2"} style={{width: 400}}>
                    <span className={"mb-2"}>
                        <FormattedMessage id={"admin.orders.details.left_table.method"}/>
                        <UncontrolledTooltip target={"gateway-info"}><FormattedMessage
                            id={"admin.orders.details.left_table.method_desc"}/></UncontrolledTooltip>
                        <i className={"text-info cursor-pointer icon-feather-info ml-1"} id={"gateway-info"}/>
                    </span>
                    <div>
                        {orderDetails.order_num ? <PaymentMethodSelect
                            isMulti={false}
                            onChange={(e) => {
                                if (e === null)
                                    e = {value: ""}

                                OrderModel.modify(this.props.orderId, {
                                    payment_method: e.value || ""
                                }).then(r => r.json()).then(r => {
                                    openSnackbar(react_intl.formatMessage({id: "admin.orders.details.left_table.method_changed"}))
                                    if (typeof this.props.reload === "function")
                                        this.props.reload()
                                })
                            }}
                            defaultValue={typeof orderDetails.payment_method.id === "number" ? {
                                value: orderDetails.payment_method.id,
                                label: orderDetails.payment_method.name
                            } : null}
                        /> : ""}
                    </div>
                </Card>
            </div>
        );
    }

    save_prepayment_order(status_new) {

        let {orderId} = this.props, self = this;
        this.setState({isPrepaymentOrder_Saving: true});

        let jsonData = JSON.stringify({
            "data": {
                "attributes": {
                    "additional_meta": [
                        {
                            "meta_key": "payment_type",
                            "meta_value": status_new ? "prepayment" : "default"
                        }
                    ]
                }
            }
        })

        GlRequest.patch('/api/v2/order/' + orderId, jsonData).then(r => r.json())
            .then(r => {
                self.setState({isPrepaymentOrder_Saving: false});
            }).catch((error) => {
            self.setState({isPrepaymentOrder_Saving: false});
            alert(error);
        })
    }
}

export default LeftTable
