import {Fragment} from "react";
import "./../assets/css/PanelApp.scss"
import AdminSidebar from "../Pages/Admin/Blocks/AdminSidebar";
import classnames from "classnames";
import {AdminMenu, is_mobile, mobileWidthEnd, pageTemplates} from "../Config";
import {get_main_config} from "../index";
import AdminMobileMenu from "../Pages/Admin/Blocks/AdminMobileMenu";
import AdminMobileProfile from "../Pages/Admin/Blocks/AdminMobileProfile";

const {Route} = require("react-router-dom");

export const detect_first_load_opened = () => {
    let url = window.location.href, selectedMenu, selectedParent,
        firstParent = null, firstMenu = null
    AdminMenu().forEach(item => {
        item.items.forEach(item2 => {
            if (firstParent === null) {
                firstParent = item2
            }
            if (typeof item2.items === "object" && Object.keys(item2.items)) {
                item2.items.forEach(item3 => {
                    if (firstMenu === null)
                        firstMenu = item3
                    if (url.indexOf(item3.href) > -1) {
                        selectedMenu = item3
                        selectedParent = item2
                    }
                })
            }
        })
    })

    if (typeof selectedParent === "undefined") {
        return {
            parent: AdminMenu()[0].items[0].slug,
            item: AdminMenu()[0].items[0]
        }
    } else {
        return {
            parent: selectedParent.slug,
            item: selectedMenu
        }
    }
}

export class AdminRoute extends Route {
    state = {
        preloaderShow: false,
        openedMenu: detect_first_load_opened().parent,
        selectedMenu: detect_first_load_opened().item,
        mobileMenu: false,
        mobileProfile: false,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        last_pathname: window.location.pathname
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if (this.state.last_pathname !== location.pathname) {
                this.setState({
                    openedMenu: detect_first_load_opened().parent,
                    selectedMenu: detect_first_load_opened().item,
                    last_pathname: location.pathname
                })
            }
        });
        let self = this;
        if(!this.windowSizeChanged) {
            this.windowSizeChanged = window.addEventListener('resize', function () {
                self.setState({
                    windowWidth: window.innerWidth,
                    windowHeight: window.innerHeight
                }, () => {
                    // console.log(self.state.windowWidth < mobileWidthEnd);
                });
            });
        }
    }

    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener('resize', this.windowSizeChanged);
    }

    mobileProfileToggle = () => {
        this.setState({
            mobileProfile: !this.state.mobileProfile
        })
    }

    mobileMenuToggle = () => {
        this.setState({
            mobileMenu: !this.state.mobileMenu
        })
    }

    constructor(props) {
        super(props);
        this.changeMenu = this.changeMenu.bind(this)
        this.setSelectedMenu = this.setSelectedMenu.bind(this)
        this.mobileProfileToggle = this.mobileProfileToggle.bind(this)
        this.mobileMenuToggle = this.mobileMenuToggle.bind(this)
    }

    changeMenu(slug, callback) {
        if (slug === this.state.openedMenu && slug !== "home")
            slug = null;
        this.setState({openedMenu: slug}, () => {
            if (typeof callback === "function") {
                callback()
            }
        })
    }

    setSelectedMenu(item, callback) {
        this.setState({selectedMenu: item}, () => {
            if (typeof callback === "function")
                callback()
        })
    }

    render() {
        const {component: Component}: AdminRoute = this.props;
        let pageTemplate = (typeof this.props.template === "string" && typeof pageTemplates[this.props.template] === "object") ? this.props.template : "standard";
        const containerInnerClass = pageTemplates[pageTemplate].containerInnerClass;
        const wrapperClass = pageTemplates[pageTemplate].wrapperClass;
        return <Fragment>
            <div className={classnames("loading-div", {"d-none": !this.state.preloaderShow})}>
                <div className="gooey">
                    <span className="dot"/>
                    <div className="dots">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div id="wrapper" className={wrapperClass}>
                <div className="dashboard-container">
                    {<AdminSidebar
                        {...this.props}
                        toggleMenu={this.mobileMenuToggle}
                        toggleProfile={this.mobileProfileToggle}
                        openedMenu={this.state.openedMenu}
                        selectedMenu={this.state.selectedMenu}
                        changeMenu={this.changeMenu}
                        changeSelectedMenu={this.setSelectedMenu}
                    />}
                    {is_mobile() ? <AdminMobileMenu
                        {...this.props}
                        open={this.state.mobileMenu}
                        openedMenu={this.state.openedMenu}
                        selectedMenu={this.state.selectedMenu}
                        changeMenu={this.changeMenu}
                        changeSelectedMenu={this.setSelectedMenu}
                        toggle={this.mobileMenuToggle}
                    /> : ""}
                    {this.state.windowWidth < mobileWidthEnd && <AdminMobileProfile
                        {...this.props}
                        open={this.state.mobileProfile}
                        toggle={this.mobileProfileToggle}
                        changeMenu={this.changeMenu}
                        changeSelectedMenu={this.setSelectedMenu}
                    />}
                    <div className="dashboard-content-container" style={{height: "100vh"}}>
                        <div className={containerInnerClass}>
                            <Component {...this.props} />
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    }
}

export const GetTitle = (prefix) => {
    window.scrollTo(0, 0);
    let title = get_main_config("system_general", "site_name")
    if (typeof title === "undefined" || title === "")
        return "paymendo"
    return prefix + ' ▸ ' + get_main_config("system_general", "site_name")
}
