import React from  "react"
import {Badge, Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import {FormattedMessage} from "react-intl";

import {Card, CardHeader} from "@material-ui/core";
import classnames from "classnames";
import MethodSettings from "./MethodSettings";
import ButtonLoad from "../../../Elements/ButtonLoad";
import PaymentMethodModel from "../../../Models/PaymentMethodModel";
import {react_intl} from "../../../index";

class AddBankAccountModal extends React.Component{

    state = {
        createMethodStep: 1,
        methodFilter: "",
        createMethodClassTemp: null,
        addMethodModal: false
    }

    addMethodModalToggle = (e) => {
        this.setState({
            createMethodClass: "",
            createMethodClassTemp: "",
            addMethodModal: !this.state.addMethodModal,
            createMethodStep: 1
        })
    }

    methodFilterHandle = (e) => {
        this.setState({
            methodFilter: e.target.value
        })
    }

    constructor(props) {
        super(props);
        this.methodFilterHandle = this.methodFilterHandle.bind(this)
        this.addMethodModalToggle = this.addMethodModalToggle.bind(this)
        this.methodSettingHandler = this.methodSettingHandler.bind(this)

        if(typeof this.props.callback === "function")
            this.props.callback(this);
    }

    render() {
        let {loadActiveMethods} = this.props
        let {setMethodType} = this.props
        let bankDetails = {}, bankClass
        let {
            methodSelected = {},
            activeMethodClass
        } = this.state

        let {
            openSnackbar,
            modalTitle,
            buttonText,
            methodType,
            accountList,
            accountGet
        } = this.props
        let {
            activeMethodSettings,
        } = this.state

        if (Object.keys(methodSelected).length)
            bankClass = methodSelected.class

        if (this.state.createMethodStep === 2 && this.state.createMethodClass)
            bankClass = this.state.createMethodClass

        else if (typeof activeMethodClass === "string")
            bankClass = activeMethodClass
        if (bankClass)
            bankDetails = accountGet(bankClass)
        let activeBankSettings = typeof bankDetails.settings === "object" && bankDetails.settings !== null ? bankDetails.settings : {}

        let filtered_banks = Object.keys(accountList)
            .filter(e => {
                if (this.state.methodFilter === "")
                    return true
                let item = accountList[e]
                return ((item.name).toLowerCase()).indexOf(this.state.methodFilter) > -1
            })


        return <>
            <Modal id={`${methodType}-Modal`} isOpen={this.state.addMethodModal} toggle={this.addMethodModalToggle}
                   size={"lg"}
                   centered={true}
                   returnFocusAfterClose={false}
            >
                <ModalHeader toggle={this.addMethodModalToggle}>
                    {modalTitle}
                    <Badge>{`${this.state.createMethodStep}/2`}</Badge>
                </ModalHeader>
                <ModalBody>
                    {this.state.createMethodStep === 1 &&
                    <div className={"padding-bottom-25 padding-left-25 padding-right-25"}>
                        <Row className={"bg-light padding-top-10"}>
                            <Col sm={12} md={7}>
                                <h4 style={{lineHeight: "38px"}}><FormattedMessage
                                    id={"admin.payment.methods.select_gateway"}/></h4>
                            </Col>
                            <Col sm={12} md={{size: 5}}>
                                <Input
                                    style={{width: "calc(100% - 30px)"}}
                                    className={"float-left"}
                                    onChange={this.methodFilterHandle} type="text"/>
                                <i className={"icon-feather-search float-right text-center"} style={{
                                    width: 30,
                                    lineHeight: "35px"
                                }}/>
                            </Col>
                        </Row>

                        <Row className={"padding-top-25"}>{filtered_banks.length ? filtered_banks.map((key, i) => {
                            let item = accountList[key]
                            let e = {
                                label: item.name,
                                value: key
                            }
                            let selected = e.value === this.state.createMethodClassTemp
                            return <Col sm={6} lg={3} className={"mb-3"} key={`Method-${i}`}>
                                <Card
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    className={classnames("new-payment-method-box", {"selected": selected})}
                                    onClick={clc => this.setState({createMethodClassTemp: e.value}, () => {
                                        let _bankObject = accountGet(e.value)
                                        let settings = []
                                        if (typeof _bankObject.settings === "object") {
                                            let item = null
                                            Object.keys(_bankObject.settings).forEach(key => {
                                                item = _bankObject.settings[key]
                                                settings.push({
                                                    name: key,
                                                    value: typeof item.default !== "undefined" ? item.default : ""
                                                })
                                                this.setState({
                                                    activeMethodSettings: settings
                                                })
                                            })
                                        }
                                    })}>
                                    <div style={{
                                        textAlign: "center",
                                        width: "100%",
                                        height: 70,
                                        background: `url('${(item.image || {}).default}') no-repeat center`,
                                        backgroundSize: "auto 125px",
                                        backgroundPosition: "center center"
                                    }}>
                                    </div>
                                    <CardHeader
                                        className={classnames("text-center border-top p-2", {
                                            "bg-light": !selected,
                                            "bg-dark text-white": selected
                                        })}
                                        style={{height: 50}}
                                        disableTypography
                                        title={item.name}
                                    />
                                </Card>
                            </Col>
                        }) : <h4 className={"text-center w-100"}><i className={"icon-line-awesome-ban"}/>
                            <FormattedMessage id={"admin.payment.methods.no_any_gateway"}/></h4>}
                        </Row>
                    </div>}
                    {this.state.createMethodStep === 2 && <>
                        <Table className={""}>
                            <tbody>
                            {activeBankSettings ? Object.keys(activeBankSettings).map(key => {
                                let item = activeBankSettings[key]
                                return <MethodSettings
                                    z_index_value={item.zIndex || "0"}
                                    settingKey={key}
                                    value={(activeMethodSettings.find(item => item.name === key) || {}).value}
                                    details={item}
                                    handler={this.methodSettingHandler}
                                />
                            }) : ""}
                            </tbody>
                        </Table>
                    </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            this.setState({
                                createMethodClassTemp: "",
                                createMethodStep: 1
                            })
                        }}
                        color={"info"}
                        className={"float-left"}><i className={"icon-feather-arrow-left"}/> <FormattedMessage
                        id={"admin.payment.methods.back"}/></Button>
                    <ButtonLoad
                        onClick={() => {
                            if (this.state.createMethodStep === 1) {
                                let temp = this.state.createMethodClassTemp
                                this.setState({
                                    createMethodClass: temp,
                                    createMethodClassTemp: "",
                                    createMethodStep: 2
                                })
                            }
                            if (this.state.createMethodStep === 2) {
                                this.setState({createLoading: true})
                                PaymentMethodModel.create({
                                    name: accountList[this.state.createMethodClass].name,
                                    description: accountList[this.state.createMethodClass].name,
                                    settings: this.state.activeMethodSettings,
                                    class: this.state.createMethodClass,
                                    method_type: methodType
                                }).then(r => r.json()).then(r => {
                                    let addedMessage;

                                    if(methodType === "bankaccount")
                                        addedMessage = react_intl.formatMessage({id: "admin.payment.methods.bank_account_added"});
                                    else
                                        addedMessage = react_intl.formatMessage({id: "admin.payment.methods.added"});

                                    openSnackbar(addedMessage);
                                    this.setState({createLoading: false})
                                    this.addMethodModalToggle()
                                    if(typeof loadActiveMethods === "function")
                                        loadActiveMethods()
                                    if(typeof setMethodType === "function")
                                        setMethodType(methodType)
                                })
                            }
                        }}
                        disabled={this.state.createMethodClass < 2 && this.state.createMethodClassTemp === ""}
                        color={"primary"}
                        text={this.state.createMethodStep === 2 ? react_intl.formatMessage({id: "admin.payment.methods.next"}) : react_intl.formatMessage({id: "admin.payment.methods.next"})}
                        loading={this.state.createLoading}
                    />
                </ModalFooter>
            </Modal>
            <Button
                onClick={this.addMethodModalToggle}
                color={this.props.buttonColor || "secondary"}>{buttonText}</Button>
        </>;
    }

    methodSettingHandler = (e) => {
        if (e

                .target
                .type
            ===
            "checkbox"
        )
            e
                .target
                .value = e.target.checked ? "on" : "off"
        let
            state = this.state.activeMethodSettings
        let
            _name = e.target.name
        let
            _value = e.target.value
        let
            exists = false
        state = state.map(item => {
            if (item.name === _name) {
                item.value = _value
                exists = true
            }
            return item
        })

        if (

            !
                exists
        )
            state
                .push({
                    name: _name,
                    value: _value
                })

        this
            .setState({
                activeMethodSettings: state
            })
    }

}
export default AddBankAccountModal
