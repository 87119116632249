import {Button, Col, Label, Row} from "reactstrap";
import GlSelect, {react_select_zIndex99_style} from "../../../../Components/GlSelect";
import React from "react";
import NumberFormat from 'react-number-format';
import CurrencyModel from "../../../../Models/CurrencyModel";
import OrderModel from "../../../../Models/OrderModel";
import {ApiErrorThrow} from "../../../../Config";
import ButtonLoad from "../../../../Elements/ButtonLoad";
import {Select38Px} from "../../../../Conts";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../../index";

class BottomTable extends React.Component {
    state = {
        order_details_modified: {},
        saveData: {}
    }

    getData(slug) {
        let {order_details_modified} = this.state
        let {orderDetails} = this.props
        return typeof order_details_modified[slug] !== "undefined" ? order_details_modified[slug] : orderDetails[slug]
    }

    render() {
        let {orderDetails} = this.props
        return <div>
            <div className="content with-padding bg-white">
                <Row>
                    <Col sm={12} md={5} lg={3}><Label><FormattedMessage
                        id={"admin.orders.details.bottom_table.order_notes"}/></Label></Col>
                    <Col>
                        <textarea
                            onChange={(e) => {
                                let value = e.target.value
                                let _modified = Object.assign({}, this.state.order_details_modified)
                                _modified.notes = value
                                this.setState({
                                    order_details_modified: _modified
                                })
                                this.props.handle({
                                    target: {
                                        name: "notes",
                                        value: value
                                    }
                                })
                            }}
                            className={"form-control"} name={"notes"} onClick={(e) => {
                        }} defaultValue={orderDetails.notes} />
                    </Col>
                </Row>
                <Row className={"pt-3"}>
                    <Col sm={12} md={5} lg={3}><Label><FormattedMessage
                        id={"admin.orders.details.bottom_table.order_amount"}/></Label></Col>
                    <Col sm={6} md={5} lg={5}>
                        <NumberFormat
                            className={"order-total-input"}
                            onValueChange={(values) => {
                                let _modified = Object.assign({}, this.state.order_details_modified)
                                _modified.amount = values.floatValue
                                this.setState({
                                    order_details_modified: _modified
                                })
                                this.props.handle({
                                    target: {
                                        name: "amount",
                                        value: values.floatValue
                                    }
                                })
                            }}
                            defaultValue={(orderDetails.amount)}
                            displayType={'input'}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            allowNegative={false}
                            prefix={this.getData("currency").prefix}
                            suffix={this.getData("currency").suffix}
                            decimalScale={this.getData("currency").currency_decimal}
                        />
                    </Col>
                    <Col lg={2} className={"text-left text-lg-right"}>
                        <Label><FormattedMessage id={"admin.orders.details.bottom_table.currency"}/></Label>
                    </Col>
                    <Col sm={6} md={2} lg={2}>
                        <GlSelect
                            url={'/currency'}
                            firstSelected={true}
                            closeMenuOnSelect={true}
                            styles={Object.assign(Select38Px, {
                                container: (provided, state) => ({
                                    ...provided,
                                    zIndex: 99
                                })
                            })}
                            placeholder={react_intl.formatMessage({id: "admin.orders.details.bottom_table.currency_placeholder"})}
                            onChange={(val) => {
                                let code = val.value
                                if (typeof this.props.orderDetailsUpdate === "function") {
                                    let modified = {...orderDetails}
                                    modified.currency = val.details
                                    this.props.orderDetailsUpdate(modified)
                                }
                                this.props.handle({
                                    target: {name: "currency_code", value: val.value}
                                })
                            }}
                            defaultValue={{
                                value: orderDetails.currency.id,
                                label: orderDetails.currency.code
                            }}
                        />
                    </Col>
                </Row>
            </div>
            <div className="content with-padding bg-light border-top">
                <Row>
                    <Col>
                        <p className={"text-right"}>
                            <ButtonLoad
                                color={"dark"}
                                text={react_intl.formatMessage({id: "admin.orders.details.bottom_table.save"})}
                                loading={this.props.loading}
                                onClick={() => this.props.save()}
                                size={"lg"}><FormattedMessage
                                id={"admin.orders.details.bottom_table.save"}/></ButtonLoad>
                        </p>
                    </Col>
                </Row>
            </div>
        </div>;
    }
}

export default BottomTable
