import React from "react";
import {
    Button,
    Col,
    Label,
    Row
} from "reactstrap";
import {debounce, transactionTypes} from "../../../Config";
import {matchPath, withRouter} from "react-router";
import ShowPrice from "../../../Elements/ShowPrice";
import DataTable from "../../../Components/DataTable";
import moment from "moment";
import {OrderStatuses} from "../../../Elements/OrderStatus";
import InputWithIcon from "../../../Elements/InputWithIcon";
import {Link} from "react-router-dom";
import Select from "react-select";
import DateRangePickerWrapper from "../../../Components/react-dates/DateRangePickerWrapper";
import {GetTitle} from "../../../Routes/AdminRoute";
import {getBankObject} from "../../../Banks";
import {Select38Px} from "../../../Conts";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";
import {getBankObjectBankAccount} from "../../../BankAccounts";

export const clientTransactionsTableColumns = () => {
    return [
        {
            Header: react_intl.formatMessage({id: "admin.clients.transactions.table_cols.date"}),
            width: "15%",
            accessor: "attributes.date",
            Cell: row => {
                return <div>
                    {moment(row.value).format('DD/MM/YYYY HH:mm')}
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.clients.transactions.table_cols.transaction_no"}),
            accessor: "attributes.transaction_id",
            width: "15%",
            Cell: row => {
                return <div>
                    <code>{row.value || "-"}</code>
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.clients.transactions.table_cols.gateway"}),
            width: "15%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                let {payment_method, installment, used_point} = row
                let installmentOutput = parseInt(installment) > 1 ? react_intl.formatMessage({id: "admin.clients.transactions.table_cols.gateway.x_installment"}, {number: installment}) : react_intl.formatMessage({id: "admin.clients.transactions.table_cols.gateway.advance"});
                let method_class = payment_method.class

                let bObject;
                if(typeof payment_method.method_type === "string" && payment_method.method_type === "bankaccount")
                    bObject = getBankObjectBankAccount(method_class)
                else
                    bObject = getBankObject(method_class)

                if(typeof bObject !== "object") bObject = {

                }

                return <div>
                    <div style={{
                        width: "110px",
                        height: 25,
                        background: `url('${(bObject.image || {}).default}') no-repeat left center`,
                        backgroundSize: "50%"
                    }} alt=""></div>
                    {`${payment_method.name} - ${installmentOutput}`}
                    {used_point ?
                        <div><FormattedMessage id={"admin.clients.transactions.table_cols.gateway.used_point"}/>
                            <ShowPrice currencyprefix={row.currency.prefix}
                                       currencysuffix={row.currency.suffix}
                                       amount={row.amount}/></div> : ""}
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.clients.transactions.table_cols.amount"}),
            width: "15%",
            sortKey: "attributes.total_amount",
            Cell: data => {
                let row = data.row.original

                if (typeof row.currency === "undefined")
                    row.currency = {}
                let currency = row.currency
                row = row.attributes

                return <div>
                    <ShowPrice
                        currencysuffix={currency.suffix}
                        amount={row.total_amount}
                        currencyprefix={currency.prefix}
                    />
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.clients.transactions.table_cols.actions"}),
            width: "40%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <div>
                    <Link to={`/admin/orders/${row.order_id.id}`} className={"btn btn-dark"}>
                        <FormattedMessage id={"admin.clients.transactions.table_cols.actions.details"}/>
                    </Link>
                </div>
            }
        }
    ]
}
const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/admin/clients/:clientId/transactions`,
    });
    return (matchProfile && matchProfile.params) || {};
};

class SingleClientTransactions extends React.Component {
    tableRef = React.createRef()
    state = {
        descriptionFilter: "",
        statusFilter: "all",
        dateFilterStart: null,
        dateFilterEnd: null
    }
    clientId = null

    onTransactionFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);
    statusList = []
    columns = clientTransactionsTableColumns()

    constructor(props) {
        super(props);
        let params = getParams(props.location.pathname)
        this.clientId = params.clientId
        this.statusList = function () {
            let list = []
            OrderStatuses().map(item => {
                list.push({
                    label: item.label,
                    value: item.slug
                })
            })
            return list
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let prevPathname = prevProps.location.pathname
        let pathname = this.props.location.pathname
        if (prevPathname !== pathname) {
            let params = getParams(pathname)
            this.clientId = params.clientId;
            this.loadClientData()
        }
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "admin.clients.transactions.title"}))
    }

    render() {
        let {dateFilterStart, dateFilterEnd} = this.state
        return <div>
            <Row className={"mt-3 mb-3"}>
                <Col sm={12} lg={4} className={"text-left"}>
                    <Link
                        key={"transactionToProfileLink"}
                        to={"/admin/clients/" + this.clientId}
                        className={"btn btn-sm btn-dark rounded-100 mr-3"}><i
                        className={"icon-line-awesome-arrow-left"}/> <FormattedMessage
                        id={"admin.clients.transactions.profile_link"}/></Link>
                    <h3 className="pb-3 pt-3"><FormattedMessage
                        id={"admin.clients.transactions.title_account_movements"}/></h3>
                </Col>
                <Col sm={12} lg={8}>
                    <Row>
                        <Col sm={4}>
                            <Label>
                                <div
                                    dangerouslySetInnerHTML={{__html: react_intl.formatMessage({id: "admin.clients.transactions.filters.trans_id"})}}/>
                            </Label>
                            <InputWithIcon
                                onChange={(e) => {
                                    this.setState({descriptionFilter: e.target.value})
                                    this.onTransactionFilterChanged()
                                }}
                            />
                        </Col>
                        <Col sm={2}>
                            <Label><FormattedMessage id={"admin.clients.transactions.filters.type"}/></Label>
                            <Select
                                styles={Select38Px}
                                placeholder={"Seçiniz"}
                                onChange={(value) => {
                                    this.setState({
                                        statusFilter: value.value
                                    }, () => {
                                        this.onTransactionFilterChanged()
                                    })
                                }}
                                defaultValue={transactionTypes()[0]}
                                options={transactionTypes()}
                            />
                        </Col>
                        <Col sm={6}>
                            <Label><FormattedMessage id={"admin.clients.transactions.filters.date_range"}/></Label>
                            <DateRangePickerWrapper
                                showClearDates={true}
                                onChange={(start, end) => {
                                    this.setState({
                                        dateFilterStart: start ? start.toDate() : null,
                                        dateFilterEnd: end ? end.toDate() : null
                                    }, () => {
                                        this.tableRef.current.loadData()
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12}>
                    <hr/>
                </Col>
                <Col sm={12}>
                    <div className="alone-table stick-table-footer border border-top-0 rounded">
                        <DataTable
                            noFakeRow={true}
                            ref={this.tableRef}
                            orderBy={"id"}
                            order={"desc"}
                            columns={this.columns} dataQuery={{
                            url: "/api/v2/transaction",
                            filters: {
                                user_id: this.clientId,
                                concat: this.state.descriptionFilter,
                                status: this.state.statusFilter === "all" ? "" : this.state.statusFilter,
                                date_start: dateFilterStart ? moment(dateFilterStart).format("DD-MM-YYYY") : "",
                                date_end: dateFilterEnd ? moment(dateFilterEnd).format("DD-MM-YYYY") : ""
                            }
                        }}/>
                    </div>
                </Col>
            </Row>
        </div>;
    }

}

export default withRouter(SingleClientTransactions)
