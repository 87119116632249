import GlRequest from "../Lib/GlRequest";

class PaymentMethodModel {
    getActiveMethods(include,method_type) {
        if(typeof method_type !== "string" || method_type === "")
            method_type = "vpos"
        let url = `/payment-method?page[size]=999`
        if (typeof include === "object")
            url += "&include=" + include.join(",")

        url+="&method_type="+method_type
        return GlRequest.get(url)
    }

    delete(id, newId) {
        return GlRequest.delete(`/api/v2/payment-method/` + id + '/' + newId)
    }

    getById(id) {
        return GlRequest.get(`/paymentmethods/` + id)
    }

    saveSettings(id, details) {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.patch(`/api/v2/payment-method/` + id, details, 'json')
    }

    setDefault(id) {
        return this.saveSettings(id, {
            is_default: true
        })
    }

    create(data) {
        data = {
            data: {
                attributes: data
            }
        }
        return GlRequest.post('/api/v2/payment-method', data, 'json')
    }
}

export default PaymentMethodModel = new PaymentMethodModel()
