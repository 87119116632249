import React from "react"
import {Label} from "reactstrap";

class MultiRadio extends React.Component {
    render() {
        let {register, required, options, name, value, onChange} = this.props
        if(typeof register !== "function") register = (p1,p2) => {}
        return <div>
            {options.map((item,i) => <div className={"d-inline mr-2"} key={`RadioGroup-${i}`}>
                <div className={`radio${typeof item.color === "string" ? ` radio-${item.color}` : ""}`}>
                    <input
                        {...register(name, {required: required})}
                        id={`${name}-item-${item.value || item.slug}`} name={name}
                           onChange={onChange}
                           type="radio"
                           value={item.value || item.slug}
                           checked={value === (item.value || item.slug)}
                    />
                    <label htmlFor={`${name}-item-${item.value || item.slug}`}><span
                        className="radio-label"></span> {item.label}</label>
                </div>
            </div>)}
        </div>;
    }
}

export default MultiRadio
