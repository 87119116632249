import React from "react"
import {
    Button,
    Col,
    Input,
    Label,
    PopoverBody,
    PopoverHeader,
    Row,
    UncontrolledPopover,
    UncontrolledTooltip
} from "reactstrap";
import Select from "react-select";
import InputWithIcon from "../../../Elements/InputWithIcon";
import {Cvc, Number} from "react-credit-card-primitives";
import {getTypeIcon} from "../QuickPayment/QuickCreditCard";
import moment from "moment";
import axessLogo from "../../../assets/images/cc-marks/axess_logo.png"
import bankkartLogo from "../../../assets/images/cc-marks/bankkart_logo.png"
import bonusLogo from "../../../assets/images/cc-marks/bonus_logo.jpg"
import cardfinansLogo from "../../../assets/images/cc-marks/cardfinans_logo.png"
import maximumLogo from "../../../assets/images/cc-marks/maximum_logo.png"
import parafLogo from "../../../assets/images/cc-marks/paraf_logo.png"
import worldLogo from "../../../assets/images/cc-marks/world_logo.png"
import cvv from "../../../assets/images/cvv.jpg"
import InstallmentBox from "./InstallmentBox";
import {Alert, AlertTitle} from "@material-ui/lab";
import PaymentModel from "../../../Models/PaymentModel";
import classnames from "classnames";
import {Select38Px} from "../../../Conts";
import {ApiErrorThrow, mobileWidthEnd} from "../../../Config";
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl";
import {AiFillWarning, FaExclamationCircle} from "react-icons/all";
import {react_intl} from "../../../index";

const card_logos = {
    axess: axessLogo,
    bankkart: bankkartLogo,
    bonus: bonusLogo,
    cardfinans: cardfinansLogo,
    maximum: maximumLogo,
    paraf: parafLogo,
    world: worldLogo
}
const get_card_logo = (cardBrand) => {
    if (typeof card_logos[cardBrand] === "undefined")
        return ""
    return card_logos[cardBrand];
}

const CreditCardView = (props) => {
    let {number, expiry, holder, cvv, flip, cardType, issuer, brand, ccFocus} = props
    let number_parts = number.match(/.{1,4}/g)
    if (number_parts === null)
        number_parts = []

    if (!ccFocus)
        for (let i = 0; i < 3; i++)
            number_parts[i] = '••••';

    let numbers = [0, 1, 2, 3].map(item => {
        let out = typeof number_parts[item] !== "undefined" ? number_parts[item] : "••••";
        out.padEnd(4, "•");
        return out;
    })


    return <div className={"credit-card-image"}>
        <div className={classnames("ccard " + brand, {
            "flipped": flip
        })}>
            <div className="flip">
                <div className="front">
                    <div className="strip-bottom"></div>
                    <div className="strip-top"></div>
                    <div className="logo">
                        {brand && <img src={get_card_logo(brand)} width={64}/>}
                    </div>
                    <div className="investor">
                        {issuer || "-"}
                    </div>
                    <div className="chip">
                        <div className="chip-line"></div>
                        <div className="chip-line"></div>
                        <div className="chip-line"></div>
                        <div className="chip-line"></div>
                        <div className="chip-main"></div>
                    </div>
                    <div className="ccard-number">
                        {numbers.map(item => <div className={"section"}>{item}</div>)}
                    </div>
                    <div className="end"><span className="end-text">exp. end:</span><span
                        className="end-date"> {expiry}</span></div>
                    <div className="ccard-holder">{holder}</div>
                    <div className="master">
                        {getTypeIcon(cardType, 42)}
                    </div>
                </div>
                <div className="back">
                    <div className="strip-black"></div>
                    <div className="ccv">
                        <div>{cvv}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const month_list = moment.monthsShort().map((item, key) => {
    let _key = key + 1
    return {
        label: `${_key.toLocaleString('en-US', {
            minimumIntegerDigits: 2
        })} - ${item}`, value: _key.toLocaleString('en-US', {
            minimumIntegerDigits: 2
        })
    }
})

const year_list = () => {
    let options = []
    let currentYear = new Date().getFullYear()
    for (let a = 0; a < 15; a++) {
        options.push({
            value: currentYear + a,
            label: currentYear + a
        })
    }
    return options
}

const defaultInstallments = [{
    count: 1,
    total: 0,
    extraInstallment: 0
}]

const initialState = {
    bin: "000000",
    installments: defaultInstallments,
    installmentsLoaded: false,
    cardBrand: "",
    cardIssuer: "",
    isBusiness: false,
    flipped: false,
    ccFocus: false,
    numberValid: false
}

class CreditCardTab extends React.Component {
    state = initialState
    cardType = ""

    resetState = () => {
        let changeHandle = this.props.onChange
        changeHandle("number", "")
        changeHandle("holder", "")
        changeHandle("expiry_month", "")
        changeHandle("expiry_year", "")
        changeHandle("cvv", "")
        this.setState(initialState)
    }

    getCardData = (bin) => {
        PaymentModel.getBinInfo(bin).then(r => r.json())
            .then(r => {
                let cardData = r.data.attributes
                let {brand, card_type, isBusiness, bank} = cardData

                this.setState({
                    cardBrand: brand,
                    cardIssuer: bank,
                    isBusiness
                })
            }).catch(r => {
            this.setState({
                cardBrand: "",
                cardIssuer: "",
                isBusiness: false
            })
        })
    }

    binChanged = () => {
        let {bin} = this.state
        let {orderId} = this.props

        if (bin.length === 0) {
            this.setState({
                installments: defaultInstallments,
                installmentsLoaded: false
            })
            this.setInstallment(1)
        }

        if (bin.length < 6)
            return false
        bin = bin.substr(0, 6)
        let check_bin
        if (typeof this.props.auth === "boolean" && this.props.auth)
            check_bin = PaymentModel.checkBin(bin, orderId)
        else
            check_bin = PaymentModel.checkBinWithToken(bin, this.state.token)


        check_bin.then(r => r.json()).then(r => {
            let {data} = r
            let {attributes} = data
            let {installment} = attributes

            let installment_filtered = Object.keys(installment).map(i => {
                let _installment = installment[i],
                    extra = 0;

                let _num = i.replaceAll('installment', '')
                _num = _num.replaceAll('s', '')
                if (typeof _installment === "object") {
                    return {
                        total: parseFloat(_installment.total),
                        count: parseInt(_num),
                        extraInstallment: typeof _installment.extra_installment !== "undefined" ?_installment.extra_installment : 0
                    };
                }
                return null;
            })

            this.setInstallment(1)
            this.setState({
                installments: installment_filtered.filter(item => item !== null),
                installmentsLoaded: true,
            }, () => this.getCardData(bin))
        }).catch(() => {
            this.setInstallment(1)
            this.setState({
                installmentsLoaded: true,
                installments: defaultInstallments
            }, () => this.getCardData(bin))
        })
    }


    setBin = (val) => {
        val = val.replaceAll(' ', '')
        val = val.substr(0, 6)
        if (this.state.bin !== val) {
            this.setState({bin: val}, this.binChanged)
        }
    }

    setInstallment = (num) => {
        if (typeof this.props.installment_changed === "function")
            this.props.installment_changed(num)
    }

    constructor(props) {
        super(props);
        if (typeof props.setRef === "function")
            props.setRef(this)
        this.orderTokenChanged = this.orderTokenChanged.bind(this);
    }

    orderTokenChanged = (e) => {
        let {detail} = e;
        this.setState({
            token: detail.token
        })
    }

    componentDidMount() {
        window.addEventListener('orderTokenChanged', this.orderTokenChanged);
    }

    componentWillUnmount() {
        window.removeEventListener('orderTokenChanged', this.orderTokenChanged);
    }

    render() {
        let self = this
        let {
            details,
            orderDetails,
            theme,
            numberValidation,
            expiryValidation,
            holderValidation,
            cvvValidation,
            isValidNumber,
            isValidCVV,
            isValidExp,
            isValidHolder,
            expValidNotChanged
        } = this.props
        let {formatMessage} = this.props.intl
        let {ccFocus} = this.state

        if (details.holder === "")
            details.holder = ""

        let isMobile = mobileWidthEnd >= window.innerWidth;
        let changeHandle = this.props.onChange;
        let ccColumn = <Col sm={12} lg={theme === "quick" ? 7 : 6}>
            <div className={isMobile ? "" : "p-3"}>
                <CreditCardView
                    issuer={this.state.cardIssuer}
                    brand={this.state.cardBrand}
                    number={details.number}
                    ccFocus={this.state.ccFocus}
                    expiry={details.expiry_month || details.expiry_year ? `${details.expiry_month} / ${details.expiry_year}` : ''}
                    holder={details.holder}
                    cvv={details.cvv}
                    flip={this.state.flipped}
                    cardType={this.cardType}
                />
            </div>
        </Col>

        return <div className={"padding-bottom-25"}>
            <Alert
                className={""}
                severity={"success"} icon={<i className={" icon-material-outline-lock"}/>}>
                <AlertTitle><FormattedMessage id={"client.credit_card_tab.security_msg"}/></AlertTitle>
                <FormattedMessage id={"client.credit_card_tab.security_info"}/>
            </Alert>
            <p>&nbsp;</p>

            <div className={classnames("", {
                "row": !isMobile
            })}>
                {isMobile && ccColumn}
                <Col sm={12} lg={theme === "quick" ? 5 : 6}>
                    <table className={classnames("border-0", {
                        "ml-3": !isMobile && (theme !== "quick")
                    })} border={0} cellPadding={0} cellSpacing={0} width={"100%"}>
                        <tbody>
                        <Number
                            masked={!ccFocus}
                            cardTypes={[]}
                            onChange={(d) => {
                                this.setBin(d.value)
                                changeHandle("number", d.value)
                                if (typeof numberValidation === "function")
                                    numberValidation(d.valid)
                            }}
                            defaultValue={details.number}
                            render={({value, valid, type, getInputProps}) => {
                                self.cardType = type

                                return <>
                                    <tr>
                                        <th colSpan={2} className={"padding-right-20"}>
                                            <Label
                                                className={"font-weight-light font-size-18"}
                                                for={"cc-form-number"}><FormattedMessage
                                                id={"client.credit_card_tab.card_no"}/> {!ccFocus && details.number !== "" && !isValidNumber && <>
                                                <FaExclamationCircle id={"cc-number-error-icon"}
                                                                     className={"animated infinite pulse"}/>
                                                <UncontrolledTooltip target={"cc-number-error-icon"}>{react_intl.formatMessage({id:"client.cc_info_invalid.cc_number"})}</UncontrolledTooltip>
                                            </>}</Label>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <Input
                                                id={"cc-form-number"}
                                                name={"number"}
                                                {...getInputProps()}
                                                onFocus={() => {
                                                    this.setState({ccFocus: true})
                                                }}
                                                onBlur={() => {
                                                    this.setState({ccFocus: false})
                                                }}
                                                placeholder={"•••• •••• •••• ••••"}
                                            />
                                        </td>
                                    </tr>
                                </>
                            }}/>

                        <tr>
                            <th colSpan={2} className={"font-weight-normal"}>
                                <Label
                                    className={"font-weight-light font-size-18"}
                                    for={"cc-form-month"}><FormattedMessage id={"client.credit_card_tab.expiry_date"}/>
                                    {!isValidExp && !expValidNotChanged ? <>
                                        <FaExclamationCircle id={"cc-number-error-icon"}
                                                             className={"animated infinite pulse"}/>
                                        <UncontrolledTooltip target={"cc-number-error-icon"}>{react_intl.formatMessage({id:"client.cc_info_invalid.expiry_date"})}</UncontrolledTooltip>
                                    </> : ""}
                                </Label>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Row>
                                    <Col className={"text-left"}>
                                        <Select
                                            id={"cc-form-month"}
                                            styles={Select38Px}
                                            placeholder={formatMessage({id: "client.credit_card_tab.month"})}
                                            options={month_list}
                                            name={"expiry_month"}
                                            onChange={(e) => {
                                                expiryValidation(true)
                                                changeHandle("expiry_month", e.value)
                                            }}
                                        />
                                    </Col>
                                    <Col className={"text-left"}>
                                        <Select
                                            styles={Select38Px}
                                            placeholder={formatMessage({id: "client.credit_card_tab.year"})}
                                            options={year_list()}
                                            name={"expiry_year"}
                                            onChange={(e) => {
                                                expiryValidation(true)
                                                changeHandle("expiry_year", e.value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>&nbsp;</th>
                        </tr>
                        <tr>
                            <th colSpan={2} className={"font-weight-normal"}>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Label
                                            className={"font-weight-light font-size-18"}
                                            for={"cc-form-holder"}><FormattedMessage id={"client.credit_card_tab.card_owner"}/>
                                            {!isValidHolder ? <>
                                                <FaExclamationCircle id={"cc-number-error-icon"}
                                                                     className={"animated infinite pulse"}/>
                                                <UncontrolledTooltip target={"cc-number-error-icon"}>{react_intl.formatMessage({id:"client.cc_info_invalid.holder"})}</UncontrolledTooltip>
                                            </> : ""}
                                        </Label>
                                        <Input
                                            id={"cc-form-holder"}
                                            name={"holder"}
                                            onKeyPress={event => (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)}
                                            onChange={(e) => {
                                                let v = e.target.value
                                                holderValidation(v !== "")
                                                changeHandle("holder", v)
                                            }}
                                            value={details.holder}
                                        />

                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <Label
                                            className={"font-weight-light font-size-18"}
                                            for={"cc-form-cvv"}>{theme === "quick" || isMobile  ? "CVC" : formatMessage({id: "client.credit_card_tab.security_code"})}
                                            {!isValidCVV && details.cvv !== "" && !this.state.flipped ? <>
                                                <FaExclamationCircle id={"cc-number-error-icon"}
                                                                     className={"animated infinite pulse"}/>
                                                <UncontrolledTooltip target={"cc-number-error-icon"}>{react_intl.formatMessage({id:"client.cc_info_invalid.cvv"})}</UncontrolledTooltip>
                                            </> : ""}
                                        </Label>
                                        {" "}
                                        <Button id="CvvPopover" color={""} size={"sm"} className={"p-0"}
                                                type="button">
                                            <i className={"icon-line-awesome-question-circle"} id={"cvv-info"}/>
                                        </Button>
                                        <UncontrolledPopover trigger="focus" placement="bottom" target="CvvPopover">
                                            <PopoverHeader><FormattedMessage
                                                id={"client.credit_card_tab.cvv_code_info"}/></PopoverHeader>
                                            <PopoverBody>
                                                <img src={cvv} width={250}/>
                                            </PopoverBody>
                                        </UncontrolledPopover>

                                        <Cvc
                                            onChange={(d) => {
                                                if (typeof cvvValidation === "function")
                                                    cvvValidation(d.valid)

                                                changeHandle("cvv", d.value)
                                            }}
                                            name={"cvv"}
                                            cardType={undefined}
                                            render={({getInputProps, value, valid}) => (
                                                <Input
                                                    {...getInputProps()}
                                                    placeholder={""}
                                                    id={"cc-form-cvv"}
                                                    onFocus={() => {
                                                        self.setState({flipped: true})
                                                    }}
                                                    onBlur={() => {
                                                        self.setState({flipped: false})
                                                    }}
                                                    value={details.cvv}
                                                    style={
                                                        {
                                                            width: "100%"
                                                        }
                                                    }
                                                />
                                            )}/>
                                    </Col>
                                </Row>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Row>
                                    <Col>

                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Col>
                {!isMobile && ccColumn}
            </div>
            <Row className={classnames({
                "d-none": !this.state.installmentsLoaded
            })}>
                <Col className={"text-left"}>
                    <div id="installmentInputs" className={"padding-left-10 padding-right-10"}>
                        {this.state.installments.map(item => {
                            return <InstallmentBox
                                selected={this.props.installment === item.count}
                                onClick={() => {
                                    this.setInstallment(item.count)
                                }}
                                currency={orderDetails.currency || {}}
                                total={item.total} count={item.count}
                                extraInstallment={item.extraInstallment}
                            />
                        })}
                    </div>
                </Col>
            </Row>
        </div>
    }
}

export default injectIntl(CreditCardTab)
