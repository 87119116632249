import {Button, Col, Row} from "reactstrap";
import React from "react";
import {ClientAuthorizationObject} from "../../Lib/Authorization";
import AdminSidebarProfile from "../Admin/Blocks/AdminSidebarProfile";
import {get_main_config, react_intl} from "../../index";
import {
    AiFillHome,
    AiOutlineUser, FaBars, FaHamburger, FaList, FaTimes,
    GoVerified, GrAnnounce,
    GrList,
    RiHistoryFill
} from "react-icons/all";
import {frontendBaseUrl, is_mobile} from "../../Config";
import classnames from "classnames";
import {FormattedMessage, injectIntl} from "react-intl";

const menu = () => [
    {
        link: "/cp",
        label: "",
        mobile_label: "Anasayfa",
        icon: <AiFillHome/>
    },
    {
        link: "/cp/quick-payment",
        label: react_intl.formatMessage({id: "client.header.quick_pay"}),
        icon: <GoVerified/>
    },
    {
        link: "/cp/orders",
        label: react_intl.formatMessage({id: "client.header.invoices"}),
        icon: <GrList/>
    },
    {
        link: "/cp/transactions",
        label: react_intl.formatMessage({id: "client.header.payments"}),
        icon: <RiHistoryFill/>
    },
    {
        link: "/cp/announcements",
        label: react_intl.formatMessage({id: "client.header.campaign_announcements_txt"}),
        icon: <GrAnnounce/>
    },
    {
        link: "/cp/settings",
        label: react_intl.formatMessage({id: "client.header.my_account"}),
        icon: <AiOutlineUser/>
    }

]

class Header extends React.Component {
    state = {
        loading_logout: false,
        mobileMenuOpened: false
    }
    mobileMenuToggle=()=>{
        this.setState({
            mobileMenuOpened: !this.state.mobileMenuOpened
        })
    }
    render() {
        let {history} = this.props
        let link = window.location.href
        link = link.replace(frontendBaseUrl, '')
        let topMenuList = <>
            {ClientAuthorizationObject.isLoggedIn() ? menu().map(item => {
                if(typeof item.mobile_label === "string")
                    item.label = item.mobile_label;
                return <li
                    key={`header-menu-item-${item.label}`}
                    className={classnames("cursor-pointer mr-2 ml-2", {
                        active: link === item.link
                    })}
                    onClick={() => {
                        if(is_mobile()) this.mobileMenuToggle()
                        history.push(item.link)
                    }}
                >{typeof item.icon !== "undefined" && item.icon}<span
                    className={"ml-1"}>{item.label}</span></li>
            }) : ""}
            {" "}
            <li className={"float-none float-md-right w-auto profile-badge"}>
                {ClientAuthorizationObject.isLoggedIn() ?
                    <AdminSidebarProfile
                        style={{
                            lineHeight: "32px",
                            float: "right",
                            marginTop: 12
                        }}
                        onClick={() => {
                            history.push('/cp')
                        }}
                        history={this.props.history}
                        type={"client"}
                    />
                    :
                    <Button size={"sm"} color={"primary"} onClick={() => {
                        history.push("/cp/login")
                    }}><FormattedMessage id={"client.header.consumer_login"}/></Button>
                }
            </li>
        </>

        return <header id="header-container" className="fullwidth position-relative">
            <div id="header">
                <div className="container">
                    <div className="d-flex">
                        <div id="logo" className={classnames("cursor-pointer pl-0 pr-3", {
                            "w-100": is_mobile()
                        })} onClick={() => {
                            this.props.history.push('/')
                        }}>
                            <img src={get_main_config("system_general", "logo_url")}
                                 alt={"logo"}
                            />
                        </div>

                        {is_mobile() && <div className={"p-3"}>
                            <Button
                                onClick={() => {
                                    this.mobileMenuToggle()
                                }}
                                color={"default"} className={"float-right"}>
                                <FaBars />
                            </Button>
                            <div className={classnames("admin-mobile-menu p-3 animated", {
                                "fadeIn": this.state.mobileMenuOpened,
                                "d-block": this.state.mobileMenuOpened
                            })}>
                                <Row>
                                    <Col>
                                        <nav>
                                            <ul>
                                                {topMenuList}
                                            </ul>
                                        </nav>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className={"text-center position-fixed"} style={{
                                        right: 20,
                                        top: 20
                                    }}>
                                        <Button color={"danger"} onClick={() => this.mobileMenuToggle()}>
                                            <FaTimes />
                                        </Button>
                                    </div>
                                </Row>
                            </div>
                        </div>}

                        <nav id="navigation" className={"w-100 user-panel-top-menu"}>
                            <ul id="responsive" className={"w-100"} style={{lineHeight: "55px"}}>
                                {topMenuList}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    }
}

export default injectIntl(Header)
