import {backendBaseUrl, get_profile_info} from "../Config";
import moment from "moment";
import {Redirect} from "react-router-dom";
import GlRequest from "./GlRequest";


class ClientAuthorization {
    name = "ClientAuth"
    tokenRefreshing = false

    isLoggedIn = () => {
        let token = localStorage.getItem("auth_access_token")
        return (typeof token === "string") && (token !== "")
    }

    doLogout = () => {
        return new Promise(function (resolve, reject) {
            let auth_keys = ["admin_profile", "client_auth_id", "auth_access_token", "auth_refresh_token", "auth_expired_at", "auth_expires_in", "auth_token_type"]
            auth_keys.forEach(item => {
                localStorage.removeItem(item)
            })
            resolve()
        })
    }

    getProfile = () => {
        return new Promise(function (resolve, reject) {
            GlRequest.get('/api/v2/get-current-user').then(r => r.json())
                .then(r => {
                    if (typeof r !== "object" || typeof r.data !== "object")
                        resolve({})
                    else
                        resolve(r.data.attributes)
                }).catch(r => reject(r))
        })
    }

    doLogin = (email, pw) => {
        return new Promise(function (resolve, reject) {
            fetch(backendBaseUrl + '/login', {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        attributes: {
                            grant_type: "password",
                            login: email,
                            auth: pw
                        }
                    }
                })
            }).then(r => r.json()).then(result => {
                let role = ""
                if (typeof result.error_description !== "undefined") {
                    let _error = `Error: ${result.error_description}`
                    reject(_error)
                    return;
                }
                if (typeof result.access_token !== "undefined") {
                    localStorage.setItem("auth_expired_at", Date.now() + parseInt(result.expires_in) * 1000);
                    Object.keys(result).forEach((item, index) => {
                        let item_value = result[item];
                        localStorage.setItem("auth_" + item, item_value);
                    });

                    resolve({role});
                } else {
                    reject(result.message)
                }
            });
        })
    }

    doRegister = (details) => {
        return new Promise(function (resolve, reject) {
            console.log('RegisterFetch');
            fetch(backendBaseUrl + '/user', {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        attributes: details
                    }
                })
            }).then(r => r.json()).then(result => {
                if (typeof result.errors !== "undefined") {
                    let _error = `Error: ${(result.errors).map(i => i.detail).join(",")}`
                    reject(_error)
                    return;
                }
                if (typeof result.status !== "undefined" && result.status) {
                    resolve(true);
                } else {
                    reject(result.message)
                }
            });
        })
    }

    isExpired() {
        let expired_at = localStorage.getItem("auth_expired_at")
        if (expired_at)
            expired_at = parseInt(expired_at)
        return moment(new Date(expired_at)).isBefore(moment())
    }

    refresh() {
        this.tokenRefreshing = true

        let self = this, refreshToken = localStorage.getItem("auth_refresh_token")
        return new Promise(function (resolve, reject) {
            if (typeof refreshToken !== "string" || refreshToken === "") {
                setTimeout(function () {
                    self.tokenRefreshing = false
                }, 1000);
                reject("NoRefresh")
            }
            fetch(backendBaseUrl + '/login', {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        attributes: {
                            grant_type: "refresh_token",
                            auth: refreshToken,
                            login: get_profile_info('email')
                        }
                    }
                })
            })
                .then(r => {
                    if(typeof r.ok === "boolean" && r.ok) return r;
                    self.tokenRefreshing = false
                    reject("InvalidGrant");
                    return r;
                })
                .then(r => r.json()).then(result => {
                if (typeof result.error === "string" && result.error === "invalid_grant") {
                    setTimeout(function () {
                        self.tokenRefreshing = false
                    }, 1000);
                    reject("InvalidGrant")
                }
                if (typeof result.access_token !== "undefined") {
                    localStorage.setItem("auth_expired_at", Date.now() + parseInt(result.expires_in) * 1000);
                    Object.keys(result).forEach((item, index) => {
                        let item_value = result[item];
                        localStorage.setItem("auth_" + item, item_value);
                    });
                    setTimeout(function () {
                        self.tokenRefreshing = false
                    }, 1000);
                    resolve(result.access_token)
                }
            }).catch(error => {
                console.log("RefreshError", error);
            });
        })
    }

    async getAccessToken() {
        let self = this
        if (this.isExpired()) {
            let token = ""
            await self.refresh().then(function (tokenRefresh) {
                token = tokenRefresh;
            }).catch(function (reason) {
                if (reason === "NoRefresh" || reason === "InvalidGrant") {
                    self.doLogout().then(function () {
                        window.location.href = '/cp/login'
                        return false
                    })
                }
            })
            return "Bearer " + token
        } else {
            let token = localStorage.getItem("auth_access_token")
            if (token === null || token.length === 0) {
                window.location.href = '/cp/login';
                return false;
            }
            return `Bearer ${token}`
        }
    }
}

export const GlobalAuthorizationObject = new ClientAuthorization()
export const ClientAuthorizationObject = new ClientAuthorization()
