import React from "react";
import {Badge, Col, Label} from "reactstrap";
import {debounce, bankTransactionTypes} from "../../../Config";
import DataTable from "../../../Components/DataTable";
import moment from "moment";
import Select from "react-select";
import {Link} from "react-router-dom";
import {OrderStatuses} from "../../../Elements/OrderStatus";
import InputWithIcon from "../../../Elements/InputWithIcon";
import DateRangePickerWrapper from "../../../Components/react-dates/DateRangePickerWrapper";
import ShowPrice from "../../../Elements/ShowPrice";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import {generateQueryParams, Select38Px} from "../../../Conts";
import {GetTitle} from "../../../Routes/AdminRoute";
import ClientSelect from "../../../Elements/ClientSelect";
import MultiRadio from "../../../Elements/MultiRadio";
import UserLink from "../../../Elements/UserLink";
import {getBankObject} from "../../../Banks";
import PaymentMethodSelect from "../../../Elements/PaymentMethodSelect";
import {getBankObjectBankAccount} from "../../../BankAccounts";
import {BsFillArrowDownCircleFill, BsFillArrowUpCircleFill} from "react-icons/all";

class BankAccountTransactions extends React.Component {
    tableRef = React.createRef()
    state = {
        // clientFilter: "",
        // typeFilter: "",
        // titleFilter: "",
        // transactionNumFilter: "",
        // statusFilter: "transferred_in,transferred_out",
        // dateFilterStart: "",
        // dateFilterEnd: "",
        payment_method: ""
    }
    columns = [
        {
            Header: 'ID #',
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            mobileWidth: "20%",
            Cell: row => {
                return <span className="id-cell">{"#" + row.value}</span>
            }
        },
        {
            Header: 'Hareket Tipi',
            accessor: null,
            sortKey: 'status',
            width: "15%",
            mobileWidth: "20%",
            Cell: data => {
                let row = data.row.original
                let {attributes} = row
                return <div className="id-cell">{attributes.status === "transferred_in" ?
                    <div className={"w-50 text-center"}>
                        <BsFillArrowDownCircleFill className={"text-success"} size={32}/>
                        <span className={"small d-block"}>Tahsilat</span>
                    </div>
                    : <div className={"w-50 text-center"}>
                        <BsFillArrowUpCircleFill className={"text-danger"} size={32}/>
                        <span className={"small d-block"}>Ödeme</span>
                    </div>}</div>
            }
        },
        {
            Header: 'Tutar',
            accessor: null,
            width: "10%",
            mobileWidth: "20%",
            Cell: data => {
                let row = data.row.original
                return <ShowPrice
                    amount={row.attributes.total_amount} currencyprefix={row.currency.prefix}
                    currencysuffix={row.currency.suffix}
                />
            }
        },
        {
            Header: 'Tarih',
            accessor: 'attributes.date',
            width: "15%",
            hideMobile: true,
            Cell: row => {
                return moment(row.value).format("DD-MM-YYYY HH:mm")
            }
        },
        {
            Header: 'Ödeme Yöntemi',
            accessor: null,
            width: "15%",
            mobileWidth: "20%",
            Cell: data => {
                let row = data.row.original
                let currency = row.currency
                row = row.attributes
                let {payment_method, installment, used_point} = row
                let method_class = payment_method.class
                let installmentOutput = parseInt(installment) > 1 ? `${installment} Taksit` : "Peşin";
                let bObject = getBankObjectBankAccount(method_class)
                if (typeof bObject === "undefined") bObject = {}
                return <div className={"text-center"}>
                    <div className={"transaction-gw-logo"} style={{
                        background: `url('${(bObject.image || {}).default}') no-repeat center center`
                    }} alt=""></div>
                    <span className={""}>{`${installmentOutput}`}</span>
                    {used_point ? <div>Kullanılan Puan <ShowPrice currencyprefix={currency.prefix}
                                                                  currencysuffix={currency.suffix}
                                                                  amount={row.amount}/></div> : ""}
                </div>
            }
        },
        {
            Header: 'Açıklama',
            accessor: null,
            width: "20%",
            hideMobile: true,
            Cell: data => {
                let row = data.row.original;
                row = row.attributes;
                return <>
                    {row.transaction_id && <span className={"d-block small"}>{row.transaction_id}</span>}
                    <span className={"d-block"} title={row.notes}>{row.notes}</span>
                </>
            }
        },
        {
            Header: 'İşlemler',
            width: "15%",
            mobileWidth: "20%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                if (typeof row.order_id === "object" && row.order_id.id) {
                    return <Link
                        to={`/admin/orders/${(row.order_id || {}).id}`}
                        className={"btn btn-sm btn-info"}
                    >Faturayı Göster</Link>
                }
                return "";
            }
        }
    ]

    constructor(props) {
        super(props);
        this.queryParameters = new URLSearchParams(window.location.search);
    }

    getFilters = () => {
        let {
            dateFilterStart, dateFilterEnd, payment_method,
            clientFilter, transactionNumFilter, statusFilter
        } = this.state

        let _filters = {}

        if(typeof clientFilter === "object")
            _filters['user_id'] = (clientFilter).join(",");

        if(typeof transactionNumFilter === "string" && transactionNumFilter)
            _filters['concat'] = transactionNumFilter;

        if(statusFilter !== "all")
            _filters['status'] = statusFilter??"";

        if(dateFilterStart)
            _filters['date_start'] = moment(dateFilterStart).format("YYYY-MM-DD");
        if(typeof dateFilterStart === "string" && !dateFilterStart) _filters['date_start'] = '';

        if(dateFilterEnd)
            _filters['date_end'] = moment(dateFilterEnd).format("YYYY-MM-DD");
        if(typeof dateFilterEnd === "string" && !dateFilterEnd) _filters['date_end'] = '';

        if(payment_method)
            _filters['payment_method'] = payment_method;

        _filters['bank_accounts'] = 1;
        [
            "user_id",
            "concat",
            "status",
            "date_start",
            "date_end",
            "payment_method"
        ].forEach(filter_key => {
            if (
                (typeof _filters[filter_key] === "undefined") &&
                this.queryParameters.get(filter_key)) {
                _filters[filter_key] = this.queryParameters.get(filter_key);
            }
        })

        if (typeof _filters.status === "undefined")
            _filters.status = "transferred_in,transferred_out";

        return _filters;
    }


    onClientFilterChanged = debounce(() => {
        if (this.tableRef.current !== null)
            this.tableRef.current.loadData()

        let url = '?',
            urlFilters = {},
            _filters = this.getFilters()

        Object.keys(_filters).forEach(item_key => {
            if(_filters[item_key] !== "transferred_in,transferred_out" && item_key !== "bank_accounts")
                urlFilters[item_key] = _filters[item_key]
        })

        url += generateQueryParams(urlFilters)
        this.props.history.push(url)

    }, 500);

    componentDidMount() {
        document.title = GetTitle('Banka Hesap Hareketleri')
    }

    render() {
        let {dateFilterStart, dateFilterEnd, payment_method} = this.state
        let selected_filters = this.getFilters()
        return <div className={"filter-150 admin-transactions admin-table"}>


            <TablePageFilterBar
                title={"Banka Hesap Hareketleri"}
                description={""}
            >
                <Col sm={6} md={2}>
                    <Label>İşlem No / Açıklama</Label>
                    <InputWithIcon
                        onChange={(e) => {
                            this.setState({transactionNumFilter: e.target.value}, this.onClientFilterChanged)
                        }}
                    />
                </Col>
                <Col md={4}>
                    <Label>Tarih Aralığı</Label>
                    <DateRangePickerWrapper
                        initialStartDate={typeof selected_filters['date_start'] === "string" && selected_filters['date_start'] !== "" ? moment(selected_filters['date_start'], "YYYY-MM-DD") : null}
                        initialEndDate={typeof selected_filters['date_end'] === "string" && selected_filters['date_end'] !== "" ? moment(selected_filters['date_end'], "YYYY-MM-DD") : null}
                        showClearDates={true}
                        onChange={(start, end) => {
                            this.setState({
                                dateFilterStart: start ? start.toDate() : "",
                                dateFilterEnd: end ? end.toDate() : ""
                            }, this.onClientFilterChanged)
                        }}
                    />
                </Col>
                <Col md={2}>
                    <Label>Ödeme Yöntemi</Label>
                    <PaymentMethodSelect
                        filters={{
                            method_type: "bankaccount"
                        }}
                        onChange={(vals) => {
                            this.setState({payment_method: vals.map(val => val.value).join(",")})
                        }}
                        onMenuClose={() => {
                            this.onClientFilterChanged()
                        }}
                        closeMenuOnSelect={false}
                    />
                </Col>
                <Col md={4}>
                    <Label>Durum</Label>
                    <MultiRadio
                        value={this.state.statusFilter || "transferred_in,transferred_out"}
                        onChange={(e) => {
                            this.setState({
                                statusFilter: e.target.value
                            }, this.onClientFilterChanged)
                        }}
                        options={bankTransactionTypes()}
                    />
                </Col>
            </TablePageFilterBar>

            <div className={"container"}>
                <DataTable
                    exportPosition={"bottom"}
                    exportBase={"/api/v2/transaction-excel"}
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    ref={this.tableRef}
                    columns={this.columns}
                    dataQuery={{
                        url: "/api/v2/transaction",
                        filters: this.getFilters(),
                        include: ["currency"]
                    }}/>
            </div>

        </div>;
    }
}

export default BankAccountTransactions
