import React from "react";
import {matchPath} from "react-router";
import OrderModel from "../../../Models/OrderModel";
import {
    Button, ButtonGroup,
    Col,
    Row,
    Spinner, TabContent,
    TabPane
} from "reactstrap";
import {ApiErrorThrow, successSnackbarOptions} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import OrderDetailsTab from "./OrderDetailsTab";
import TransactionListTab from "./TransactionListTab";
import {GetTitle} from "../../../Routes/AdminRoute";
import {FaChevronLeft} from "react-icons/all";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/admin/orders/:orderId`,
    });
    return (matchProfile && matchProfile.params) || {};
};

let defaultOrderDetails = {
    user_id: {
        firstname: "",
        lastname: "",
        id: ""
    },
    currency: {
        prefix: "",
        suffix: "",
        id: "",
        code: ""
    },
    invoice_details: false
};

class SingleOrder extends React.Component {
    orderId: null

    state = {
        isPrepaymentOrder: false,
        activeTab: "details",
        orderDetails: defaultOrderDetails,
        orderToken: "",
        additionalMetas: []
    }
    transactionRef = React.createRef()

    constructor(props) {
        super(props);
        let params = getParams(props.location.pathname)
        this.orderId = params.orderId
        this.loadOrderData = this.loadOrderData.bind(this)
        this.loadOrderData()
    }

    reload = () => {
        this.loadOrderData()
    }

    componentDidUpdate(prevProps, prevState) {
        let prevPathname = prevProps.location.pathname
        let pathname = this.props.location.pathname
        if (prevPathname !== pathname) {
            let params = getParams(pathname)
            this.clientId = params.clientId;
            this.loadOrderData()
        }
    }

    loadOrderData() {
        let {openSnackbar} = this.props

        this.setState({
            orderDetails: defaultOrderDetails
        })
        OrderModel.getById(this.orderId, ["additional_meta","currency"]).then(r => r.json()).then(r => {
            let order_token = "", additionalMetas
            if (typeof r.included === "object" && Object.keys(r.included).length) {
                let order_meta = r.included[this.orderId]
                order_token = order_meta.find(meta => meta.data.meta_key === "order_token")
                additionalMetas = order_meta
            }
            let currency = {}
            if(typeof r.included === "object" && typeof r.included.currencies !== "undefined")
            {
                currency = (r.included.currencies).find( curr => curr.data.code === r.data.attributes.currency_code )
                r.data.attributes.currency = currency.data
            }

            let _details = r.data.attributes
            if(typeof _details.payment_method === "undefined") {
                _details.payment_method = {}
            }


            let isPrepaymentOrder = (additionalMetas||[]).find(item => typeof item.type === "string" && item.type === "AdditionalMeta" && typeof item.data === "object" && item.data.meta_key === "payment_type");
            if(typeof isPrepaymentOrder === "undefined" || !isPrepaymentOrder) isPrepaymentOrder = false;
            this.setState({
                isPrepaymentOrder: (isPrepaymentOrder && isPrepaymentOrder.data.meta_value === "prepayment")
            }, () => {
            });

            this.setState({
                orderDetails: _details,
                orderToken: typeof order_token === "object" ? order_token.data.meta_value : "",
                additionalMetas: additionalMetas
            }, () => {})
        }).catch(error => {
            if (error.status === 404) {
                this.props.history.push('/admin/orders')
            } else {
                ApiErrorThrow(error, openSnackbar)
            }
        })
    }

    toggle = tab => {
        let {activeTab} = this.state
        if (activeTab !== tab) this.setState({activeTab: tab});
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"admin.orders.single.title"}))
    }

    render() {
        const {openSnackbar} = this.props
        let {orderDetails, orderToken, additionalMetas} = this.state

        return <div className={"dashboard-box order-single-page"}>
            <Row className={"p-3"}>
                <Col>
                    <ButtonGroup>
                        <Button color={"primary"} onClick={() => {
                            this.props.history.push('/admin/orders')
                        }}><FaChevronLeft style={{marginTop:"-5px"}} /> <FormattedMessage id={"admin.orders.single.all_orders"} /></Button>
                        {[{"slug": "details", "label": react_intl.formatMessage({id:"admin.orders.single.details"})},
                            {"slug": "transactions", "label": react_intl.formatMessage({id:"admin.orders.single.transactions"})}].map(item => <Button key={`button-${item.slug}`} onClick={() => {
                            this.setState({activeTab: item.slug})
                        }}
                                                                                               color={item.slug === this.state.activeTab ? "dark" : "secondary"}>{this.state.activeTab === item.slug &&
                        <i className={"icon-line-awesome-dot-circle-o mr-1"}/>}{item.label}</Button>)}
                        <Button color={"secondary"} onClick={() => {
                            this.setState({activeTab: "transactions"})
                            this.transactionRef.current.addTransactionToggle()
                            this.transactionRef.current.resetEditing()
                        }}><FormattedMessage id={"admin.orders.single.add_transaction"} /></Button>
                    </ButtonGroup>
                </Col>
                <Col className={"text-right order-single-title"}>
                    {orderDetails.order_num ? <h1>{react_intl.formatMessage({id:"admin.orders.single.order_num"}, {num:orderDetails.order_num})}</h1> :
                        <Spinner color={"info"} className={"mt-n2 ml-2 mr-2"}/>}
                </Col>
            </Row>
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="details">
                    {orderDetails && <OrderDetailsTab
                        reload={this.loadOrderData}
                        orderId={this.orderId}
                        orderToken={orderToken}
                        isPrepaymentOrder={this.state.isPrepaymentOrder}
                        orderDetails={orderDetails}
                        additionalMetas={additionalMetas}
                        orderDetailsUpdate={(orderDetails) => {
                            this.setState({
                                orderDetails
                            })
                        }}
                        {...this.props}
                    />}
                </TabPane>
                <TabPane tabId={"transactions"}>
                    <TransactionListTab
                        ref={this.transactionRef}
                        orderId={this.orderId}
                        reload={this.reload}
                        orderDetails={orderDetails} {...this.props} />
                </TabPane>
            </TabContent>
        </div>;
    }
}

export default withSnackbar(SingleOrder, successSnackbarOptions)
