const defaultBankAccountSettings = {
    "accountnumber": {
        title: "Hesap Numarası",
        description: "Kontrol etmek istediğiniz hesap numarasını yazınız.",
        type: "text",
        default: ""
    },
    "branchcode": {
        title: "Şube Kodu",
        description: "Kontrol etmek istediğiniz hesabın bağlı bulunduğu şube kodunu yazınız.",
        type: "text",
        default: ""
    },
    "branchname": {
        title: "Şube Adı",
        description: "Kontrol etmek istediğiniz hesabın bağlı bulunduğu şube adını yazınız.",
        type: "text",
        default: ""
    },
    "iban": {
        title: "IBAN",
        description: "Kontrol etmek istediğiniz banka hesabına ait IBAN bilgisini yazınız.",
        type: "text",
        default: ""
    },
    "currency": {
        title: "Para Birimi",
        description: "Bu banka hesabının para birimini seçiniz.",
        type: "select",
        options: [
            {value: "TRY", label: "Türk Lirası"},
            {value: "USD", label: "USD"},
            {value: "EUR", label: "EUR"},
            {value: "GBP", label: "GBP"},
        ],
        zIndex: 9,
        default: "TRY"
    },
    "test_mode": {
        title: "Test modu?",
        description: "Test ortamında çalışmak için aktif ediniz.",
        type: "checkbox",
        default: "1"
    }
};

export const BankAccounts = {
    "AkbankAccount": {
        image: require('./assets/images/banks/akbank.png'),
        name: "Akbank T.A.Ş.",
        class: "AkbankAccount",
        settings: {
            ...defaultBankAccountSettings
        }
    },
    "AlbarakaAccount": {
        image: require('./assets/images/banks/albaraka.png'),
        name: "Albaraka Türk Katılım Bankası",
        class: "AlbarakaAccount",
        settings: {
            ...defaultBankAccountSettings
        }
    },
    "DenizbankAccount": {
        image: require('./assets/images/banks/denizbank.png'),
        name: "DenizBank A.Ş.",
        class: "DenizbankAccount",
        settings: {
            ...defaultBankAccountSettings
        }
    },
    "GarantiAccount": {
        image: require('./assets/images/banks/garanti.png'),
        name: "T. Garanti Bankası A.Ş.",
        class: "GarantiAccount",
        settings: {
            ...defaultBankAccountSettings,
            "username": {
                title: "Kullanıcı Adı",
                description: "Banka tarafından atanan kullanıcı adını yazınız.",
                type: "text",
                default: ""
            },
            "firmcode": {
                title: "Firma Kodu",
                description: "Banka tarafından atanan firma kodunu yazınız.",
                type: "text",
                default: ""
            }
        }
    },
    "IngAccount": {
        image: require('./assets/images/banks/ingbank.png'),
        name: "ING Bank A.Ş.",
        class: "IngAccount",
        settings: {
            ...defaultBankAccountSettings
        }
    },
    "Kuveytturk": {
        image: require('./assets/images/banks/kuveytturk.png'),
        name: "Kuveyt Türk Katılım Bankası A.Ş.",
        class: "KuveytturkAccount",
        settings: {
            ...defaultBankAccountSettings,
            "username": {
                title: "Kullanıcı Adı",
                description: "Banka tarafından atanan kullanıcı adını yazınız.",
                type: "text",
                default: ""
            },
            "password": {
                title: "Kullanıcı Şifresi",
                description: "Banka tarafından atanan kullanıcı şifresini yazınız.",
                type: "password",
                default: ""
            },
            "accountSuffix": {
                title: "Hesap Son Eki(accountSuffix)",
                description: "Banka tarafından size iletilmelidir.",
                type: "text",
                default: ""
            }
        }
    },
    "QnbFinansbankAccount": {
        image: require('./assets/images/banks/finansbank.png'),
        name: "QNB Finansbank A.Ş.",
        class: "QnbFinansbankAccount",
        settings: {
            ...defaultBankAccountSettings,
            "username": {
                title: "Kullanıcı Adı",
                description: "Banka tarafından atanan kullanıcı adını yazınız.",
                type: "text",
                default: ""
            },
            "password": {
                title: "Kullanıcı Şifresi",
                description: "Banka tarafından atanan kullanıcı şifresini yazınız.",
                type: "password",
                default: ""
            },
        }
    },
    "TebAccount": {
        image: require('./assets/images/banks/teb.png'),
        name: "Türk Ekonomi Bankası A.Ş.",
        class: "TebAccount",
        settings: {
            ...defaultBankAccountSettings,
            "firmCode": {
                title: "Firma Kodu",
                description: "Banka tarafından size verilen firma kodunu yazınız.",
                type: "text",
                default: ""
            },
            "firmKey": {
                title: "Firma Key",
                description: "Banka tarafından size verilen firma key değerini yazınız.",
                type: "text",
                default: ""
            },
            "username": {
                title: "Kullanıcı Adı",
                description: "Banka tarafından atanan kullanıcı adını yazınız.",
                type: "text",
                default: ""
            },
            "password": {
                title: "Kullanıcı Şifresi",
                description: "Banka tarafından atanan kullanıcı şifresini yazınız.",
                type: "password",
                default: ""
            }
        }
    },
    "IsbankAccount": {
        image: require('./assets/images/banks/isbank.png'),
        name: "Türkiye İş Bankası A.Ş.",
        class: "IsbankAccount",
        settings: {
            ...defaultBankAccountSettings,
            "username": {
                title: "Kullanıcı Adı",
                description: "Banka tarafından atanan kullanıcı adını yazınız.",
                type: "text",
                default: ""
            },
            "password": {
                title: "Kullanıcı Şifresi",
                description: "Banka tarafından atanan kullanıcı şifresini yazınız.",
                type: "password",
                default: ""
            }
        }
    },
    "Vakifbank": {
        image: require('./assets/images/banks/vakifbank.png'),
        name: "T. Vakıflar Bankası T.A.O.",
        class: "VakifbankAccount",
        settings: {
            ...defaultBankAccountSettings,
            "MusteriNo": {
                title: "Müşteri Numarası",
                description: "Vakıfbank müşteri nurasını giriniz.",
                type: "text",
                default: ""
            },
            "KurumKullanici": {
                title: "Kullanıcı Adı",
                description: "Banka tarafından atanan kullanıcı adını yazınız.",
                type: "text",
                default: ""
            },
            "Sifre": {
                title: "Kullanıcı Şifresi",
                description: "Banka tarafından atanan kullanıcı şifresini yazınız.",
                type: "password",
                default: ""
            }
        }
    },
    "Halkbank": {
        image: require('./assets/images/banks/halkbank.png'),
        name: "Türkiye Halk Bankası A.Ş.",
        class: "HalkbankAccount",
        settings: {
            ...defaultBankAccountSettings,
            "username": {
                title: "Kullanıcı Adı",
                description: "Banka tarafından atanan kullanıcı adını yazınız.",
                type: "text",
                default: ""
            },
            "password": {
                title: "Kullanıcı Şifresi",
                description: "Banka tarafından atanan kullanıcı şifresini yazınız.",
                type: "password",
                default: ""
            }
        }
    },
    "YapikrediAccount": {
        image: require('./assets/images/banks/ykb.png'),
        name: "Yapı ve Kredi Bankası A.Ş.",
        class: "YapikrediAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "ZiraatAccount": {
        image: require('./assets/images/banks/ziraatbank.png'),
        name: "T.C. Ziraat Bankası A.Ş.",
        class: "ZiraatAccount",
        settings: {
            ...defaultBankAccountSettings,
            "username": {
                title: "Kullanıcı Adı",
                description: "Banka tarafından atanan kullanıcı adını yazınız.",
                type: "text",
                default: ""
            },
            "password": {
                title: "Kullanıcı Şifresi",
                description: "Banka tarafından atanan kullanıcı şifresini yazınız.",
                type: "password",
                default: ""
            },
            "accountAddition": {
                title: "Hesap Kodu Eki",
                description: "Hesap numrasının ek kodunu(5001 vb.) giriniz.",
                type: "text",
                default: ""
            },
            "customerNumber": {
                title: "Müşteri Numarası",
                description: "Ziraat Bankası müşteri numaranızı yazınız.",
                type: "text",
                default: ""
            }
        }
    }
}

export function getBankObjectBankAccount(bankClass) {
    return BankAccounts[bankClass]
}
