import React from "react"

class CustomCheckbox extends React.Component {
    render() {
        let randKey = parseInt(Math.random() * 1000);
        let {style} = this.props
        if(typeof style !== "object" ||  style === null)
            style = {}

        // style['pointerEvents'] = 'none'

        return <div className={"checkbox " + this.props.extraclass} {...this.props} style={style} onClick={() => {}}>
            <input type={"checkbox"} id={`input-${randKey}`} {...this.props} onClick={typeof this.props.onClick === "function" ? this.props.onClick : null} style={this.props.inputstyle}/>
            <label
                style={this.props.labelstyle}
                htmlFor={`input-${randKey}`}>
                <span style={this.props.spanstyle}
                className="checkbox-icon"></span> {this.props.label}</label>
        </div>;
    }
}

export default CustomCheckbox
